/** @format */

import { useReducer, createContext, useContext } from 'react';
import { reducer, initialState } from './reducer';
export const ChartOfAccount = createContext();

export const useChartOfAccounts = () => useContext(ChartOfAccount);

export const ChartOfAccountProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <ChartOfAccount.Provider value={[state, dispatch]}>
      {children}
    </ChartOfAccount.Provider>
  );
};
