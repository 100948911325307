/** @format */

import { useSetQuery, useRequest, useSearch } from 'hooks';

import { useLoads } from 'context';
import { capitalizeFirstLetter } from 'formatter';
import { FilterSelect } from 'filters';

import { Toast } from 'modals';

export const FilterStatus = () => {
  const [{ loadStatuses }, dispatch] = useLoads();
  const { request } = useRequest();
  const query = useSearch();
  const setQuery = useSetQuery();

  const onChange = (value) => {
    setQuery('load-status', value);
  };
  const handleFocus = () => {
    if (loadStatuses === undefined || loadStatuses?.length <= 0) {
      request({
        url: '/loads/load_status/list',
      })
        .then((res) => {
          let newData = res?.dataList?.map((value) => {
            return { key: capitalizeFirstLetter(value), value: value };
          });
          dispatch({ type: 'setLoadStatuses', payload: newData });
        })
        .catch((err) => {
          Toast({
            type: 'error',
            message: err?.message,
          });
        });
    }
  };

  return (
    <FilterSelect
      options={loadStatuses}
      onChange={onChange}
      value={capitalizeFirstLetter(query.get('load-status')) || 'ALL'}
      onDropdownVisibleChange={handleFocus}
      allOption="true"
    />
  );
};
