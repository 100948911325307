/** @format */

import { useReducer, createContext, useContext } from 'react';
import { reducer, initialState } from './reducer';

export const DefaultEmailsContext = createContext();

export const useDefaultEmails = () => useContext(DefaultEmailsContext);

export const DefaultEmailsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <DefaultEmailsContext.Provider value={[state, dispatch]}>
      {children}
    </DefaultEmailsContext.Provider>
  );
};
