/** @format */
import { dayjs } from 'day-js';
import { getCookie } from 'formatter';
import { ArrowDownIcon, ArrowUpIcon } from 'styledIcons';
import {
  Drivers,
  Employee,
  Office,
  Department,
  HrStatistics,
  Cdl,
  Users,
  Roles,
  MedicalCertificate,
  Team,
  QcTeams,
  DriverOnBoarding,
  DriversDaysOff,
  KanbanBoard,
} from 'departments/hr';
import {
  Company,
  DocType,
  Zone,
  Location,
  CategoriesPage,
  Constants,
} from 'departments/data-library';
import {
  Trucks,
  Reservation,
  Pm,
  AnnualInspection,
  PAInspection,
  WeeklyInspection,
  RegCard,
  OnBoarding,
  OrginalContracts,
  Permit,
  TruckMaintenance,
  TruckStatistics,
  TrucksBoard,
} from 'departments/fleet';
import {
  FuelManagement,
  FuelStates,
  FuelStations,
  FuelExpense,
  FuelDashboard,
  Exist,
  FuelCard,
} from 'departments/fuel';

//trailers
import {
  Trailers,
  TrailersMove,
  TrailerMovement,
  TrailerInspections,
  TrailersRegCard,
  TrailerMaintenance,
  TrailerStatistics,
} from 'departments/trailer';
import {
  Connections,
  CronTasks,
  TruckGps,
  Servers,
  DefaultEmails,
} from 'departments/settings';
import {
  SamsaraTrips,
  LoadBilling,
  BankAccount,
  TripCode,
  ChartOfAccounts,
} from 'departments/accounting';
// import Performance from 'departments/performance';
import Loads from 'departments/loads';
import { QcBoard, QcPerformance, QcStatistics } from 'departments/qc';
import {
  Policy,
  Change,
  Endorsement,
  Accident,
  InsuranceStatistics,
  AccidentExpenses,
} from 'departments/insurance';

import {
  DOTReport,
  FMCSACode,
  Eld,
  Citation,
  CitationExpense,
  SafetyDashboard,
} from 'departments/safety';

// icons
import { ReactComponent as trailer } from 'assets/icons/trailer.svg';

import { ReactComponent as truck } from 'assets/icons/truck.svg';
import { ReactComponent as hrManagement } from 'assets/icons/hrManagement.svg';
import { ReactComponent as accounting } from 'assets/icons/accounting.svg';
import { ReactComponent as calendar_icon } from 'assets/icons/calendar-schedule-2.svg';
import { ReactComponent as folders } from 'assets/icons/data-library.svg';
import { ReactComponent as settings } from 'assets/icons/settings.svg';
import { ReactComponent as dispatcher_board } from 'assets/icons/dispatcher-board.svg';
// import { ReactComponent as calendar_icon } from 'assets/icons/calendar-schedule.svg';
// import { ReactComponent as performance } from 'assets/icons/performance.svg';
import { ReactComponent as loads } from 'assets/icons/loads.svg';
import { ReactComponent as fuelTank } from 'assets/icons/fuel.svg';
import { ReactComponent as safety } from 'assets/icons/insurance-icon.svg';
import {
  DispatcherDrivers,
  Planning,
  TeamSheet,
  GrossBoard,
  DispatcherPerformance,
  PrePlanningTab,
} from 'departments/dispatcher';
import DedicatedLine from 'dedicatedLine';
import Factoring from '../../components/Departments/DataLibrary/Factoring';
import QcReport from '../../components/Departments/QcBoard/QcReport';
import ScheduledLoads from '../../components/Departments/ScheduledLoads';

const size = getCookie('size') ? getCookie('size') : 50;

export const data = [
  {
    id: 110,
    title: 'Dispatcher Board',
    Icon: dispatcher_board,
    pathname: '/department/dispatcher',
    search: '?',
    IconClosed: <ArrowDownIcon />,
    IconOpened: <ArrowUpIcon />,
    hidden: false,
    child: [
      {
        id: 10801,
        prId: 108,
        component: DispatcherDrivers,
        title: 'Board',
        Icon: null,
        pathname: '/all',
        search: '?',
        child: [],
        pageable: false,
        hidden: false,
      },
      {
        id: 10803,
        prId: 108,
        component: TeamSheet,
        title: 'Team Sheet',
        Icon: null,
        pathname: '/team-sheet',
        search: '?',
        child: [],
        pageable: false,
        hidden: false,
      },

      {
        id: 10800,
        prId: 108,
        component: GrossBoard,
        title: 'Gross Board',
        Icon: null,
        pathname: '/gross-board',
        search: '?',
        child: [],
        pageable: false,
        hidden: false,
      },
      {
        id: 10804,
        prId: 108,
        component: PrePlanningTab,
        title: 'Pre-Planning',
        Icon: null,
        pathname: '/pre-planning/*',
        search: '?',
        child: [],
        pageable: false,
        hidden: false,
      },
      {
        id: 10805,
        prId: 108,
        component: DispatcherPerformance,
        title: 'Performance',
        Icon: null,
        pathname: '/performance/*',
        search: '?',
        child: [],
        pageable: false,
        hidden: false,
      },
      {
        id: 10802,
        prId: 108,
        component: Planning,
        title: 'Load Planning',
        Icon: null,
        pathname: '/planning',
        search: '?',
        child: [],
        pageable: false,
        hidden: false,
      },
    ],
  },
  {
    id: 117,
    title: 'Planning Department',
    Icon: calendar_icon,
    pathname: '/department/planning',
    search: '?',
    IconClosed: <ArrowDownIcon />,
    IconOpened: <ArrowUpIcon />,
    hidden: false,

    child: [
      {
        id: 10511,
        component: DedicatedLine,
        title: 'Dedicated Lane',
        Icon: null,
        pathname: '/dedicated-line',
        search: '?',
        child: [],
        pageable: false,
        hidden: false,
      },
      {
        id: 105112,
        component: ScheduledLoads,
        title: 'Scheduled Loads',
        Icon: null,
        pathname: '/scheduled-loads',
        search: '?',
        child: [],
        pageable: false,
        hidden: false,
      },
    ],
  },
  {
    id: 106,
    component: Loads,
    title: 'Loads',
    Icon: loads,
    pathname: '/loads',
    search: `?size=${size}`,
    child: [],
    pageable: false,
    hidden: false,
  },
  {
    id: 121,
    title: 'QC Board',
    Icon: dispatcher_board,
    pathname: '/qc-board',
    search: '?',
    IconClosed: <ArrowDownIcon />,
    IconOpened: <ArrowUpIcon />,
    hidden: false,
    child: [
      {
        id: 12103,
        prId: 121,
        component: QcStatistics,
        title: 'Dashboard',
        Icon: null,
        pathname: '/dashboard',
        search: '?',
        child: [],
        pageable: false,
        hidden: false,
      },
      {
        id: 12104,
        prId: 121,
        component: QcBoard,
        title: 'Panel',
        Icon: null,
        pathname: '/panel/*',
        search: '?',
        child: [],
        pageable: false,
        hidden: false,
      },
      {
        id: 12105,
        prId: 121,
        component: QcPerformance,
        title: 'Performance',
        Icon: null,
        pathname: '/performance/*',
        search: '?',
        child: [],
        pageable: false,
        hidden: false,
      },
      {
        id: 12106,
        prId: 121,
        component: QcTeams,
        title: 'QC Teams',
        Icon: null,
        pathname: '/qc-teams',
        search: `?size=${size}`,
        pageable: true,
        child: [],
        hidden: false,
      },
      {
        id: 12107,
        prId: 121,
        component: QcReport,
        title: 'QC Report',
        Icon: null,
        pathname: '/reports',
        search: '',
        pageable: true,
        child: [],
        hidden: false,
      },
    ],
  },

  // {
  //   id: 107,
  //   component: Performance,
  //   title: 'KPI Board',
  //   Icon: performance,
  //   pathname: '/kpi-board/*',
  //   search: '?',
  //   child: [],
  //   pageable: false,
  //   hidden: false,
  // },

  {
    id: 103,
    title: 'Trailers Department',
    Icon: trailer,
    pathname: '/department/trailer',
    search: '?',
    IconClosed: <ArrowDownIcon />,
    IconOpened: <ArrowUpIcon />,
    hidden: false,
    child: [
      {
        id: 10300,
        prId: 103,
        component: TrailerStatistics,
        title: 'Dashboard',
        Icon: null,
        pathname: '/dashboard',
        search: '?',
        child: [],
        pageable: false,
        hidden: false,
      },
      {
        id: 1030,
        prId: 103,
        component: Trailers,
        title: 'Trailers',
        Icon: null,
        pathname: '/trailers',
        search: `?size=${size}`,
        child: [],
        pageable: true,
        hidden: false,
      },
      {
        id: 1031,
        prId: 103,
        component: TrailersMove,
        title: 'Moves',
        Icon: null,
        pathname: '/moves',
        search: `?size=${size}`,
        child: [],
        pageable: true,
        hidden: false,
      },
      {
        id: 1032,
        prId: 103,
        component: TrailerMovement,
        title: 'Movements',
        Icon: null,
        pathname: '/movements',
        search: `?size=${size}`,
        child: [],
        pageable: true,
        hidden: false,
      },

      {
        id: 1034,
        prId: 103,
        component: TrailerInspections,
        title: 'Inspections',
        Icon: null,
        pathname: '/inspections',
        search: `?size=${size}`,
        child: [],
        pageable: true,
        hidden: false,
      },
      {
        id: 1035,
        prId: 103,
        component: TrailersRegCard,
        title: 'Registration Card',
        Icon: null,
        pathname: '/registration-card',
        search: `?size=${size}`,
        child: [],
        pageable: true,
        hidden: false,
      },
      {
        id: 1036,
        prId: 103,
        component: TrailerMaintenance,
        title: 'Maintenance',
        Icon: null,
        pathname: '/maintenance',
        search: `?size=${size}&month=${dayjs().format(
          'M'
        )}&year=${dayjs().format('YYYY')}`,
        child: [],
        pageable: true,
        hidden: false,
      },
    ],
  },
  {
    id: 102,
    title: 'Fleet Department',
    Icon: truck,
    pathname: '/department/fleet',
    search: '?',
    IconClosed: <ArrowDownIcon />,
    IconOpened: <ArrowUpIcon />,
    hidden: false,
    child: [
      {
        id: 1020,
        prId: 102,
        component: TruckStatistics,
        title: 'Dashboard',
        Icon: null,
        pathname: '/dashboard',
        search: '?',
        child: [],
        pageable: false,
        hidden: false,
      },
      {
        id: 1021,
        prId: 102,
        component: Trucks,
        title: 'Trucks List',
        Icon: null,
        pathname: '/trucks',
        search: `?size=${size}`,
        child: [],
        pageable: true,
        hidden: false,
      },
      {
        id: 1026,
        prId: 102,
        component: TrucksBoard,
        title: 'Trucks Board',
        Icon: null,
        pathname: '/trucks-board',
        search: `?size=${size}`,
        child: [],
        pageable: true,
        hidden: false,
      },
      {
        id: 1022,
        prId: 102,
        component: OnBoarding,
        title: 'On Boarding',
        Icon: null,
        pathname: '/onboarding',
        search: '',
        child: [],
        pageable: false,
        hidden: false,
      },
      {
        id: 1023,
        prId: 102,
        component: TruckMaintenance,
        title: 'Truck Maintenance',
        Icon: null,
        pathname: '/truck-maintenance',
        search: `?size=${size}&month=${dayjs().format(
          'M'
        )}&year=${dayjs().format('YYYY')}`,
        child: [],
        pageable: true,
        hidden: false,
      },
      {
        id: 1024,
        prId: 102,
        component: null,
        title: 'Truck Inspection',
        Icon: null,
        pathname: '/truck-inspections',
        search: '?',
        child: [
          {
            id: 10241,
            prId: 102,
            component: PAInspection,
            title: 'PA Inspection',
            Icon: null,
            pathname: '/pa-inspection',
            search: `?size=${size}`,
            child: [],
            pageable: true,
            hidden: false,
          },
          {
            id: 10242,
            prId: 102,
            component: AnnualInspection,
            title: 'Annual Inspection',
            Icon: null,
            pathname: '/annual-inspection',
            search: `?size=${size}`,
            child: [],
            pageable: true,
            hidden: false,
          },
          {
            id: 10243,
            prId: 102,
            component: WeeklyInspection,
            title: 'Weekly Inspection',
            Icon: null,
            pathname: '/weekly-inspection',
            search: `?size=${size}`,
            child: [],
            pageable: true,
            hidden: false,
          },
        ],
      },
      {
        id: 1025,
        prId: 102,
        component: Pm,
        title: 'PM',
        Icon: null,
        pathname: '/pms',
        search: `?size=${size}`,
        child: [],
        pageable: true,
        hidden: false,
      },

      {
        id: 1027,
        prId: 102,
        component: RegCard,
        title: 'Registration Card',
        Icon: null,
        pathname: '/registration-cards',
        search: `?size=${size}`,
        child: [],
        pageable: true,
        hidden: false,
      },
      {
        id: 1028,
        prId: 102,
        component: OrginalContracts,
        title: 'Original Contracts',
        Icon: null,
        pathname: '/original-contracts',
        search: `?size=${size}`,
        child: [],
        pageable: true,
        hidden: false,
      },
      {
        id: 1029,
        prId: 102,
        component: Permit,
        title: 'Permit',
        Icon: null,
        pathname: '/permits',
        search: `?size=${size}`,
        child: [],
        pageable: true,
        hidden: false,
      },
      {
        id: 10210,
        prId: 102,
        component: Reservation,
        title: 'Reservation',
        Icon: null,
        pathname: '/reservations',
        search: `?size=${size}`,
        child: [],
        pageable: true,
        hidden: false,
      },
    ],
  },
  {
    id: 103,
    component: null,
    title: 'Fuel Department',
    Icon: fuelTank,
    pathname: '/fuel-department',
    IconClosed: <ArrowDownIcon />,
    IconOpened: <ArrowUpIcon />,
    search: '',
    child: [
      {
        id: 10239,
        prId: 103,
        component: FuelDashboard,
        title: 'Dashboard',
        Icon: null,
        pathname: '/fuel-dashboard',
        search: '',
        child: [],
        pageable: false,
        hidden: false,
      },
      {
        id: 10235,
        prId: 102,
        component: FuelManagement,
        title: 'Fuel Management',
        Icon: null,
        pathname: '/fuel-management',
        search: `?size=${size}`,
        child: [],
        pageable: true,
        hidden: false,
      },
      {
        id: 10236,
        prId: 102,
        component: FuelStates,
        title: 'Fuel States',
        Icon: null,
        pathname: '/fuel-states',
        search: `?size=${size}`,
        child: [],
        pageable: true,
        hidden: false,
      },
      {
        id: 10237,
        prId: 102,
        component: FuelStations,
        title: 'Fuel Stations',
        Icon: null,
        pathname: '/fuel-stations',
        search: `?size=${size}`,
        child: [],
        pageable: true,
        hidden: false,
      },
      {
        id: 10238,
        prId: 102,
        component: FuelExpense,
        title: 'Fuel Expense',
        Icon: null,
        pathname: '/fuel-expense',
        search: `?size=${size}`,
        child: [],
        pageable: true,
        hidden: false,
      },
      {
        id: 10239,
        prId: 102,
        component: FuelCard,
        title: 'Fuel Card',
        Icon: null,
        pathname: '/fuel-card',
        search: '?card_status=true',
        child: [],
        pageable: false,
        hidden: false,
      },
      {
        id: 10240,
        prId: 102,
        component: Exist,
        title: 'Exit',
        Icon: null,
        pathname: '/fuel-exit',
        search: `?size=${size}`,
        child: [],
        pageable: true,
        hidden: false,
      },
    ],
    hidden: false,
  },
  {
    id: 110,
    component: null,
    title: 'Insurance Department',
    Icon: safety,
    pathname: '/insurance-department',
    IconClosed: <ArrowDownIcon />,
    IconOpened: <ArrowUpIcon />,
    search: '',
    child: [
      {
        id: 11039,
        prId: 110,
        component: InsuranceStatistics,
        title: 'Dashboard',
        Icon: null,
        pathname: '/dashboard',
        search: '?',
        child: [],
        pageable: false,
        hidden: false,
      },
      {
        id: 11040,
        prId: 110,
        component: Accident,
        title: 'Accident',
        Icon: null,
        pathname: '/accident',
        search: `?size=${size}`,
        child: [],
        pageable: true,
        hidden: false,
      },
      {
        id: 11044,
        prId: 110,
        component: AccidentExpenses,
        title: 'Accident Expenses',
        Icon: null,
        pathname: '/accident-expenses',
        search: '?',
        child: [],
        pageable: true,
        hidden: false,
      },
      {
        id: 11041,
        prId: 110,
        component: Policy,
        title: 'Policy',
        Icon: null,
        pathname: '/policy',
        search: '',
        child: [],
        pageable: false,
        hidden: false,
      },
      {
        id: 11042,
        prId: 110,
        component: Change,
        title: 'Changes',
        Icon: null,
        pathname: '/changes/*',
        search: '',
        child: [],
        pageable: false,
        hidden: false,
      },
      {
        id: 11043,
        prId: 110,
        component: Endorsement,
        title: 'Endorsement',
        Icon: null,
        pathname: '/endorsement',
        search: '',
        child: [],
        pageable: false,
        hidden: false,
      },
    ],
    hidden: false,
  },
  {
    id: 115,
    component: null,
    title: 'Safety Department',
    Icon: safety,
    pathname: '/safety-department',
    IconClosed: <ArrowDownIcon />,
    IconOpened: <ArrowUpIcon />,
    search: '',
    child: [
      {
        id: 11044,
        prId: 110,
        component: SafetyDashboard,
        title: 'Dashboard',
        Icon: null,
        pathname: '/statistics',
        search: '',
        child: [],
        pageable: false,
        hidden: false,
      },
      {
        id: 11044,
        prId: 110,
        component: DOTReport,
        title: 'Report',
        Icon: null,
        pathname: '/dot-report',
        search: `?size=${size}`,
        child: [],
        pageable: true,
        hidden: false,
      },
      {
        id: 11045,
        prId: 110,
        component: Citation,
        title: 'Citation',
        Icon: null,
        pathname: '/citation',
        search: `?size=${size}`,
        child: [],
        pageable: true,
        hidden: false,
      },
      {
        id: 11046,
        prId: 110,
        component: CitationExpense,
        title: 'Citation Expense',
        Icon: null,
        pathname: '/expense',
        search: `?size=${size}`,
        child: [],
        pageable: true,
        hidden: false,
      },
      {
        id: 11047,
        prId: 110,
        component: Eld,
        title: 'ELD Board',
        Icon: null,
        pathname: '/eld',
        search: '?',
        child: [],
        pageable: false,
        hidden: false,
      },
      {
        id: 11045,
        prId: 110,
        component: FMCSACode,
        title: 'FMCSA',
        Icon: null,
        pathname: '/fmcsa-code',
        search: `?size=${size}`,
        child: [],
        pageable: true,
        hidden: false,
      },
    ],
    hidden: false,
  },
  {
    id: 100,
    title: 'HR Department',
    Icon: hrManagement,
    pathname: '/department/hr',
    search: '?',
    IconClosed: <ArrowDownIcon />,
    IconOpened: <ArrowUpIcon />,
    hidden: false,

    child: [
      {
        id: 1001,
        prId: 100,
        component: HrStatistics,
        title: 'Dashboard',
        Icon: null,
        pathname: '/dashboard',
        search: '?',
        child: [],
        hidden: false,
      },
      {
        id: 100101,
        prId: 100,
        component: KanbanBoard,
        title: 'Recruitment Kanban Board',
        Icon: null,
        pathname: '/recruitment-kanban-board',
        search: '?',
        pageable: false,
        child: [],
      },
      {
        id: 1002,
        prId: 100,
        title: 'Drivers',
        Icon: null,
        pathname: '/driver',
        search: '?',
        IconClosed: <ArrowDownIcon />,
        IconOpened: <ArrowUpIcon />,

        child: [
          {
            id: 10021,
            prId: 1002,
            component: Drivers,
            title: 'Drivers',
            Icon: null,
            pathname: '/drivers',
            search: `?size=${size}&status=CONTRACTED`,
            pageable: true,
            child: [],
            hidden: false,
          },
          {
            id: 10022,
            prId: 1002,
            component: DriverOnBoarding,
            title: 'Driver On Boarding',
            Icon: null,
            pathname: '/driver-onboarding',
            search: `?size=${size}`,
            pageable: true,
            child: [],
            hidden: false,
          },
          {
            id: 10023,
            prId: 1002,
            component: MedicalCertificate,
            title: 'Medical Certificates',
            Icon: null,
            pathname: '/medical-certificates',
            search: `?size=${size}`,
            pageable: true,
            child: [],
          },
          {
            id: 10024,
            prId: 1002,
            component: DriversDaysOff,
            title: 'Drivers Calendar',
            Icon: null,
            pathname: '/drivers-daysoff',
            search: `?size=${size}`,
            pageable: true,
            child: [],
          },

          {
            id: 10025,
            prId: 100,
            component: Cdl,
            title: 'CDL',
            Icon: null,
            pathname: '/cdl',
            search: `?size=${size}`,
            pageable: true,
            child: [],
          },
        ],
      },
      {
        id: 1003,
        prId: 100,
        title: 'Employee',
        Icon: null,
        pathname: '/employee',
        search: '?',
        IconClosed: <ArrowDownIcon />,
        IconOpened: <ArrowUpIcon />,
        hidden: false,

        child: [
          {
            id: 10031,
            prId: 1003,
            component: Employee,
            title: 'Employees',
            Icon: null,
            pathname: '/employees',
            search: `?size=${size}&status=ACTIVE`,
            pageable: true,
            child: [],
            hidden: false,
          },

          {
            id: 10032,
            prId: 1003,
            component: Office,
            title: 'Offices',
            pathname: '/offices',
            search: `?size=${size}`,
            pageable: true,
            Icon: null,
            child: [],
            hidden: false,
          },
          {
            id: 10033,
            prId: 1003,
            component: Department,
            title: 'Departments',
            pathname: '/departments',
            search: `?size=${size}`,
            pageable: true,
            Icon: null,
            child: [],
            hidden: false,
          },
        ],
      },
      {
        id: 1004,
        prId: 100,
        component: Team,
        title: 'Teams',
        Icon: null,
        pathname: '/teams',
        search: `?size=${size}`,
        pageable: true,
        child: [],
        hidden: false,
      },
      // {
      //   id: 1004,
      //   prId: 100,
      //   title: 'Teams',
      //   Icon: null,
      //   pathname: '/teams',
      //   search: '?',
      //   IconClosed: <ArrowDownIcon />,
      //   IconOpened: <ArrowUpIcon />,
      //   hidden: false,
      //   child: [
      //     {
      //       id: 10041,
      //       prId: 1004,
      //       component: Team,
      //       title: 'Teams',
      //       Icon: null,
      //       pathname: '/teams',
      //       search: `?size=${size}`,
      //       pageable: true,
      //       child: [],
      //       hidden: false,
      //     },

      //     // {
      //     //   id: 10042,
      //     //   prId: 1004,
      //     //   component: QcTeams,
      //     //   title: 'QC Teams',
      //     //   Icon: null,
      //     //   pathname: '/qc-teams',
      //     //   search: `?size=${size}`,
      //     //   pageable: true,
      //     //   child: [],
      //     //   hidden: false,
      //     // },
      //   ],
      // },
      {
        id: 1008,
        prId: 100,
        component: null,
        title: 'Users',
        Icon: null,
        pathname: '/users',
        search: '?',
        pageable: true,
        child: [
          {
            id: 10081,
            prId: 1008,
            component: Users,
            title: 'Users',
            Icon: null,
            pathname: '/users',
            search: `?size=${size}`,
            pageable: true,
            child: [],
            hidden: false,
          },
          {
            id: 1009,
            prId: 100,
            component: Roles,
            title: 'Roles',
            Icon: null,
            pathname: '/roles',
            search: `?size=${size}`,
            pageable: true,
            child: [],
            hidden: false,
          },
        ],
        hidden: false,
      },
    ],
  },
  {
    id: 117,
    title: 'Accounting Department',
    Icon: accounting,
    pathname: '/department/accounting',
    search: '?',
    IconClosed: <ArrowDownIcon />,
    IconOpened: <ArrowUpIcon />,
    hidden: false,

    child: [
      {
        id: 1171,
        prId: 117,
        component: SamsaraTrips,
        title: 'Samsara Trips',
        Icon: null,
        pathname: '/samsara-trips',
        search: '?',
        child: [],
        hidden: false,
      },
      {
        id: 1172,
        prId: 117,
        component: LoadBilling,
        title: 'Load Billing',
        Icon: null,
        pathname: '/load-billing/*',
        search: '?',
        child: [],
        hidden: false,
      },
      {
        id: 1173,
        prId: 117,
        component: BankAccount,
        title: 'Bank Account',
        Icon: null,
        pathname: '/bank-account',
        search: '?',
        child: [],
        hidden: false,
      },
      {
        id: 1174,
        prId: 117,
        component: ChartOfAccounts,
        title: 'Chart of Account',
        Icon: null,
        pathname: '/chart-of-account',
        search: '?',
        child: [],
        hidden: false,
      },
      {
        id: 1175,
        prId: 117,
        component: TripCode,
        title: 'Trip Code',
        Icon: null,
        pathname: '/trip-code',
        search: '?',
        child: [],
        hidden: false,
      },
    ],
  },
  {
    id: 101,
    title: 'Data Library',
    Icon: folders,
    pathname: '/department/data-library',
    search: '?',
    IconClosed: <ArrowDownIcon />,
    IconOpened: <ArrowUpIcon />,
    hidden: false,
    child: [
      {
        id: 1011,
        prId: 101,
        component: Company,
        title: 'Company',
        Icon: null,
        pathname: '/companies',
        search: `?size=${size}`,
        pageable: true,
        child: [],
        hidden: false,
      },
      {
        id: 1012,
        prId: 101,
        component: Constants,
        title: 'Constants',
        Icon: null,
        pathname: '/constants',
        search: `?size=${size}`,
        pageable: true,
        child: [],
        hidden: false,
      },

      {
        id: 1013,
        prId: 101,
        component: DocType,
        title: 'DocType',
        Icon: null,
        pathname: '/doc-type',
        search: `?size=${size}`,
        pageable: true,
        child: [],
        hidden: false,
      },
      {
        id: 1016,
        prId: 101,
        component: CategoriesPage,
        title: 'Categories',
        Icon: null,
        pathname: '/categories',
        search: `?size=${size}`,
        child: [],
        pageable: true,
        hidden: false,
      },
      {
        id: 1014,
        prId: 101,
        component: Location,
        title: 'Locations',
        Icon: null,
        pathname: '/locations',
        search: `?size=${size}`,
        child: [],
        pageable: true,
        hidden: false,
      },
      {
        id: 1015,
        prId: 101,
        component: Zone,
        title: 'Zone',
        Icon: null,
        pathname: '/zones',
        search: `?size=${size}`,
        child: [],
        pageable: true,
        hidden: false,
      },
      {
        id: 1016,
        prId: 101,
        component: Factoring,
        title: 'Factoring',
        Icon: null,
        pathname: '/factoring',
        search: `?size=${size}`,
        child: [],
        pageable: true,
        hidden: false,
      },
    ],
  },
  {
    id: 104,
    title: 'Settings',
    Icon: settings,
    pathname: '/department/settings',
    search: '?',
    IconClosed: <ArrowDownIcon />,
    IconOpened: <ArrowUpIcon />,
    hidden: false,
    child: [
      {
        id: 1040,
        prId: 104,
        component: Connections,
        title: 'Connections',
        Icon: null,
        pathname: '/connections',
        search: `?size=${size}`,
        pageable: true,
        child: [],
        hidden: false,
      },
      {
        id: 1041,
        prId: 104,
        component: CronTasks,
        title: 'Cron Task',
        Icon: null,
        pathname: '/cron-task',
        search: `?size=${size}`,
        pageable: true,
        child: [],
        hidden: false,
      },
      {
        id: 1042,
        prId: 104,
        component: TruckGps,
        title: 'Truck GPS',
        Icon: null,
        pathname: '/truck-gps',
        search: `?size=${size}`,
        pageable: true,
        child: [],
        hidden: false,
      },
      {
        id: 1043,
        prId: 104,
        component: Servers,
        title: 'Servers',
        Icon: null,
        pathname: '/servers',
        search: `?size=${size}`,
        pageable: true,
        child: [],
        hidden: false,
      },
      {
        id: 1044,
        prId: 104,
        component: DefaultEmails,
        title: 'Emails',
        Icon: null,
        pathname: '/default-emails',
        search: '?',
        pageable: false,
        child: [],
        hidden: false,
      },
    ],
  },
];
