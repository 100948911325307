/** @format */

import styled from 'styled-components';
import { Button as AntButton } from '../../AntDesign';

const getHeight = ({ size }) => {
  switch (size) {
    case 'large':
      return '44px';
    case 'medium':
      return '32px';
    case 'small':
      return '24px';
    default:
      return '44px';
  }
};

export const Button = styled(AntButton)`
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${({ noText }) => (noText ? 0 : '5px')};

  font-family: var(--appPrimaryFont);
  font-weight: 600;
  font-size: 12px;
  height: 30px;

  color: #1893ff;
  border: 1px solid #1893ff;
  border-radius: 4px;

  &.add {
    background-color: #1893ff;
    color: #fff;
    path {
      ${({ iconColorChange }) => {
        return iconColorChange === 'stroke'
          ? {
              stroke: '#fff',
            }
          : {
              fill: '#fff',
            };
      }}
    }
    &:hover {
      background-color: #ffffff;
      path {
        ${({ iconColorChange }) => {
          return iconColorChange === 'stroke'
            ? {
                stroke: '#1893ff',
              }
            : {
                fill: '#1893ff',
              };
        }}
      }
    }
  }

  // save button in Buttons
  &.save {
    height: 32px;
    width: 70px;
    color: #fff;
    background-color: ${({ disabled }) => (disabled ? '#999' : '#1893ff')};
    border-color: ${({ disabled }) => (disabled ? '#999' : 'inherite')};
    &:hover {
      background-color: ${({ disabled }) => (disabled ? '#999' : '#ffffff')};
      border-color: ${({ disabled }) => (disabled ? '#999' : 'inherite')};
    }
  }
  &.check {
    height: 32px;
    min-width: 100px;
    width: fit-content;
    color: #fff;
    background-color: ${({ disabled, name }) =>
      disabled
        ? '#999'
        : name === 'Checked'
        ? '#49CC90'
        : name === 'Try again'
        ? '#FF7875'
        : '#1893ff'};
    border-color: ${({ disabled, name }) =>
      disabled
        ? '#999'
        : name === 'Checked'
        ? '#49CC90'
        : name === 'Try again'
        ? '#FF7875'
        : 'inherite'};
    &:hover {
      background-color: ${({ disabled }) => (disabled ? '#999' : '#ffffff')};
      border-color: ${({ disabled }) => (disabled ? '#999' : 'inherite')};
    }
  }
  &.next {
    height: 32px;
    width: 70px;
    color: #fff;
    background-color: ${({ disabled }) => (disabled ? '#999' : '#1893ff')};
    border-color: ${({ disabled }) => (disabled ? '#999' : 'inherite')};
    &:hover {
      background-color: ${({ disabled }) => (disabled ? '#999' : '#ffffff')};
      border-color: ${({ disabled }) => (disabled ? '#999' : 'inherite')};
    }
  }
  &.iconic {
    color: var(--appIconDefaultColor);
    border-color: var(--appIconDefaultColor);
    &.active,
    &:hover {
      border-color: #1893ff;
      color: #1893ff;
      path {
        fill: #1893ff;
      }
    }
    &.active,
    &:hover {
      border-color: #1893ff;
      color: #1893ff;
      path {
        stroke: #1893ff;
      }
    }
  }
  &.collapse {
    color: var(--appIconDefaultColor);
    border-color: var(--appIconDefaultColor);
    width: fit-content;
    padding: 4px 8px;
    font-size: 12px;
  }
  // cancel button in buttons
  &#cancel {
    height: 32px;
    width: 70px;
    color: var(--appIconDefaultColor);
    border-color: var(--appIconDefaultColor);
    &:hover {
      color: #ff0000;
      border-color: #ff0000;
    }
  }
  // iconBlue button
  &.iconBlue {
    /* width: 40px; */
    height: 32px;
    /* padding: 0; */
    background-color: #1893ff;
    color: #fff;
    path {
      fill: white;
    }
    &:hover {
      background-color: #ffffff;
      path {
        fill: #1893ff;
      }
    }
  }
  &.iconBlue {
    /* width: 40px; */
    height: 32px;
    /* padding: 0; */
    background-color: #1893ff;
    color: #fff;
    path {
      fill: white;
    }
    &:hover {
      background-color: #ffffff;
      path {
        fill: #6f7070;
      }
    }
  }
  // filter button
  &.filter {
    color: var(--appIconDefaultColor);
    border-color: var(--appIconDefaultColor);
    &.active,
    &:hover {
      border-color: #1893ff;
      color: #1893ff;
      path {
        fill: #1893ff;
      }
    }
    &.active,
    &:hover {
      border-color: #1893ff;
      color: #1893ff;
      path {
        stroke: #1893ff;
      }
    }
  }
  &.outlined_simple {
    color: var(--appIconDefaultColor);
    border-color: var(--appIconDefaultColor);
    &.active,
    &:hover {
      border-color: #1893ff;
      color: #1893ff;
      path {
        fill: #1893ff;
      }
    }
    &.active,
    &:hover {
      border-color: #1893ff;
      color: #1893ff;
      path {
        stroke: #1893ff;
      }
    }
  }
  &.outlined_simple:disabled,
  &.outlined_simple:disabled:hover {
    color: #e7ebf3;
    border-color: #e7ebf3;
    path {
      fill: #b2b4b8;
    }
  }

  &.threeDots {
    color: var(--appIconDefaultColor);
    border-color: var(--appIconDefaultColor);
    &:hover {
      path {
        fill: #1893ff;
      }
    }
  }
  // export button
  &.export {
    color: var(--appIconDefaultColor);
    border-color: var(--appIconDefaultColor);
    &:hover {
      path {
        fill: #1893ff;
      }
    }
  }
  // signin button
  &.signin,
  &.reset,
  &.verify {
    width: 100%;
    background-color: #1893ff;
    color: #fff;

    .signing__in {
      display: flex;
      align-items: center;
      justify-content: center;
      grid-gap: 10px;
    }
    &:hover {
      background-color: ${({ disabled }) => (disabled ? '#999' : '#ffffff')};
      border-color: ${({ disabled }) => (disabled ? '#999' : 'inherite')};
    }
  }
  .signin:hover {
    color: #fff;
  }
  .reset:hover {
    color: #fff;
  }

  .verify:hover {
    color: #fff;
  }
  &.absolute {
    position: ${({ position }) => position};
    top: ${({ top }) => top};
    bottom: ${({ bottom }) => bottom};
    right: ${({ right }) => right};
    left: ${({ left }) => left};
  }
  &.map_button {
    font-size: ${({ fontSize }) => fontSize};
    position: ${({ position }) => position};
    top: ${({ top }) => top};
    bottom: ${({ bottom }) => bottom};
    right: ${({ right }) => right};
    left: ${({ left }) => left};
    height: ${({ height }) => height || '25px'};
    color: ${({ color }) => color};
    border-color: ${({ color, noneborder }) =>
      noneborder ? 'transparent' : color};
    box-shadow: ${({ boxshadow }) => boxshadow};
    &:hover {
      color: ${({ color }) => color};
      border-color: ${({ color }) => color};
    }
  }

  &.custom {
    position: ${({ position }) => position && position};
    top: ${({ top }) => top && top};
    right: ${({ right }) => right && right};
    z-index: ${({ zIndex }) => zIndex && zIndex};
    background-color: ${({ bgcolor }) => bgcolor};
    color: ${({ color, hovercolor }) => color || hovercolor};
    border-radius: ${({ radius }) => radius || '3px'};
    width: ${({ width }) => width || '100%'};
    height: ${({ height, size }) => height || (size && getHeight(size))};
    max-height: ${({ height, size }) => height || (size && getHeight(size))};
    font-family: var(--appPrimaryFont);
    font-style: normal;
    font-weight: ${({ weight }) => weight || 600};
    font-size: ${({ fontSize }) => fontSize || '12px'};
    cursor: pointer;
    border: ${({ type, border }) =>
      type === 'text' || type === 'link' ? 'none' : border};
    display: flex;
    grid-gap: ${({ gap }) => gap && gap};
    margin-left: ${({ margin_left }) => margin_left};
    justify-content: ${({ justify_content }) => justify_content || 'center'};
    align-items: center;
    margin-right: ${({ marginright }) => marginright};
    margin: ${({ margin }) => margin};
    align-self: ${({ alignself }) => alignself};
    position: ${({ component }) => component === 'load' && 'absolute'};
    right: ${({ component }) => component === 'load' && '0px'};
    top: ${({ component }) => component === 'load' && '8px'};
    z-index: ${({ component }) => component === 'load' && 9999};
    padding: ${({ padding }) => padding};

    &:hover {
      color: ${({ hovercolor }) => hovercolor} !important;
      border-color: ${({ hovercolor }) => hovercolor} !important;
    }
  }
  &#custom {
    background-color: ${({ bgcolor }) => bgcolor};
    color: ${({ color }) => color};
    border-radius: ${({ radius }) => radius || '2px'};
    width: ${({ width }) => width || '100%'};
    height: ${({ height, size }) => height || (size && getHeight(size))};
    font-family: var(--appPrimaryFont);
    font-weight: ${({ weight }) => weight || 600};
    font-size: ${({ fontSize }) => fontSize || '12px'};
    border: ${({ border }) => border};
    &:hover {
      opacity: 0.6;
    }
  }
  &.zone_filter {
    width: 15px;
    height: 29px;
    background-color: #fff;
    color: black;
    margin: 0 3px 0 0;
    justify-content: center;
    border-color: #d9d9d9;
  }

  &.date_range {
    padding: 0;
    width: 100%;
    max-width: 100px;
    min-width: 70px;
    box-sizing: border-box;
    height: 29px;
    /* width: 100%; */
    border: 1px solid #d9d9d9;
    height: var(--appFilterHeight);
    border-radius: var(--appFilterBorderRadius);
    font-size: var(--appFilterFontSize);
    color: #d9d9d9;
    cursor: text;
    &:hover {
      color: #d9d9d9 !important;
    }
  }
`;

export const DownloadingSpinner = styled.img`
  width: 20px;
  height: 20px;
`;
