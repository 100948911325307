/** @format */

export const initialState = {
  // boolean
  drawerOpen: false,
  customerDrawerOpen: false,
  // objects
  dataList: [],
  tripCodeList: [],
  permission: {},
  // numbers
  total: 0,
  // function
  refetch: null,
  saveFunction: () => {},
  onCancelFunction: () => {},
  stateChange: false,
};

export const reducer = (state, action) => {
  switch (action.type) {
    case 'setSaveFunction':
      return { ...state, saveFunction: action.payload };
    case 'setOnCancelFunction':
      return { ...state, onCancelFunction: action.payload };
    case 'setStateChange':
      return { ...state, stateChange: action.payload };
    case 'setDrawerOpen':
      return { ...state, drawerOpen: action.payload };
    case 'setCustomerDrawerOpen':
      return { ...state, customerDrawerOpen: action.payload };
    //! setting objects
    case 'setDataList':
      return { ...state, dataList: action.payload };
    case 'setTripCodeList':
      return { ...state, tripCodeList: action.payload };
    case 'setPermission':
      return { ...state, permission: action.payload };
    //! setting numbers for pagination
    case 'setTotal':
      return { ...state, total: action.payload };
    //! function
    case 'setRefetch':
      return { ...state, refetch: action.payload };
    default:
      return state;
  }
};
