/** @format */

export const initialState = {
  // close confirm
  saveFunction: () => {},
  onCancelFunction: () => {},
  stateChange: false,
  // =================
};

export const reducer = (state, action) => {
  switch (action.type) {
    case 'setPermission':
      return { ...state, permission: action.payload };
    case 'isFetching':
      return { ...state, isFetching: action.payload };
    case 'setSaveFunction':
      return { ...state, saveFunction: action.payload };
    case 'setOnCancelFunction':
      return { ...state, onCancelFunction: action.payload };
    case 'setStateChange':
      return { ...state, stateChange: action.payload };

    default:
      return state;
  }
};
