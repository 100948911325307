/** @format */

import { useReducer, createContext, useContext } from 'react';
import { reducer, initialState } from './reducer';

export const TripCodeContext = createContext();
export const useTripCode = () => useContext(TripCodeContext);

export const TripCodeProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <TripCodeContext.Provider value={[state, dispatch]}>
      {children}
    </TripCodeContext.Provider>
  );
};
