/** @format */

import React from 'react';

const SamsaraTrips = React.lazy(() => import('./SamsaraTrips'));
const LoadBilling = React.lazy(() => import('./LoadBilling'));
const TripCode = React.lazy(() => import('./TripCode'));
const BankAccount = React.lazy(() => import('./BankAccount'));
const ChartOfAccounts = React.lazy(() => import('./ChartOfAccounts'));

export { SamsaraTrips, LoadBilling, TripCode, BankAccount, ChartOfAccounts };
