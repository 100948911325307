/** @format */

export const initialState = {
  emailsList: [],
  drawerOpen: false,

  selected: {},
  closeDrawer: () => {},
  refetch: () => {},
};

export const reducer = (state, action) => {
  switch (action.type) {
    case 'setEmailsList':
      return { ...state, emailsList: action.payload };
    case 'setDrawerOpen':
      return { ...state, drawerOpen: action.payload };
    case 'setRefetch':
      return { ...state, refetch: action.payload };
    case 'refetch':
      return { ...state, refetch: action.payload };
    case 'closeDrawer':
      return { ...state, closeDrawer: action.payload };
    case 'setSelected':
      return { ...state, selected: action.payload };
    default:
      return state;
  }
};
