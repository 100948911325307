/** @format */

import { useReducer, createContext, useContext } from 'react';
import { reducer, initialState } from './reducer';

export const AdditionDeductionContext = createContext();

export const useAdditionDeduction = () => useContext(AdditionDeductionContext);

export const AdditionDeductionProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <AdditionDeductionContext.Provider value={[state, dispatch]}>
      {children}
    </AdditionDeductionContext.Provider>
  );
};
