/** @format */
/**  eslint-disable react/prop-types*/

import styled from 'styled-components';
import { ReactComponent as TickCircleFilled } from 'assets/icons/checked-cirlce-filled.svg.svg';
import { ReactComponent as Close } from 'assets/icons/close-line.svg';
import { ReactComponent as Delete } from 'assets/icons/delete.svg';
import { ReactComponent as Edit } from 'assets/icons/pen.svg';
import { ReactComponent as Filter } from 'assets/icons/filter.svg'; // in use
import { ReactComponent as Plus } from 'assets/icons/plus.svg'; // in use
import { ReactComponent as Minus } from 'assets/icons/minus.svg';
import { ReactComponent as Download } from 'assets/icons/download.svg'; // in use
import { ReactComponent as Draft } from 'assets/icons/draft.svg';
import { ReactComponent as ArrowLeftDoubleLL } from 'assets/icons/arrow-left-double.svg';
import { ReactComponent as ArrowRightDoubleRR } from 'assets/icons/arrow-right-double.svg';
import { ReactComponent as ArrowLeftSingleLL } from 'assets/icons/arrow-left-s-line.svg'; // in use
import { ReactComponent as ArrowRightSingleRR } from 'assets/icons/arrow-right-s-line.svg';
import { ReactComponent as ArrowDown } from 'assets/icons/arrow-down.svg';
import { ReactComponent as ArrowUp } from 'assets/icons/arrow-up.svg';
import { ReactComponent as ArrowDownLine } from 'assets/icons/arrow-down-line.svg';
import { ReactComponent as Refresh } from 'assets/icons/refresh.svg';
import { ReactComponent as ThreeDot } from 'assets/icons/more-fill.svg';
import { ReactComponent as ArrowSLine } from 'assets/icons/arrow-right-s-line.svg';
import { ReactComponent as Truck } from 'assets/icons/truck.svg';
import { ReactComponent as TrailerModel } from 'assets/icons/trailer-model.svg';
import { ReactComponent as Map } from 'assets/icons/map-icon.svg';
import { ReactComponent as zoneHeaderIcon } from 'assets/icons/zoneHeaderIcon.svg';
import { ReactComponent as Note } from 'assets/icons/new-note.svg';
import { ReactComponent as tickIcon } from 'assets/icons/tickIcon.svg';
import { ReactComponent as incomplete } from 'assets/icons/folder-no-file.svg';
import { ReactComponent as Error } from 'assets/icons/exclamation-circle-filled-red.svg'; // in use
import { ReactComponent as location } from 'assets/icons/location-mark-outlined-white.svg';
import { ReactComponent as Search } from 'assets/icons/search.svg';
import { ReactComponent as WiFiOn } from 'assets/icons/wifi-line.svg';
import { ReactComponent as WiFiError } from 'assets/icons/wifi-error.svg';
import { ReactComponent as StickerMain } from 'assets/icons/sticker-main.svg';
import { ReactComponent as Upload } from 'assets/icons/upload-2-line.svg';
import { ReactComponent as TruckNumber } from 'assets/icons/trailer-number.svg';
import { ReactComponent as Lightning } from 'assets/icons/lightning.svg';
import { ReactComponent as Status } from 'assets/icons/trailer-status.svg';
import { ReactComponent as Year } from 'assets/icons/calendar-rounded.svg';
import { ReactComponent as TruckYear } from 'assets/icons/trailer-year.svg';
import { ReactComponent as Make } from 'assets/icons/truck-inspection.svg';
import { ReactComponent as Vendor } from 'assets/icons/trailer-vendor.svg';
import { ReactComponent as plate_state } from 'assets/icons/plate_state.svg';
import { ReactComponent as OwnerShip } from 'assets/icons/trailer-ownership.svg';
import { ReactComponent as Key } from 'assets/icons/key.svg';
import { ReactComponent as License } from 'assets/icons/license.svg';
import { ReactComponent as TruckColor } from 'assets/icons/color-truck.svg';
import { ReactComponent as plateNumber } from 'assets/icons/trailer-plate-number.svg';
import { ReactComponent as Company } from 'assets/icons/building-outlined.svg';
import { ReactComponent as drug_test } from 'assets/icons/drugtest.svg';
import { ReactComponent as not_required } from 'assets/icons/not-required.svg';
import { ReactComponent as Odometer } from 'assets/icons/odometer.svg';
import { ReactComponent as trailer } from 'assets/icons/trailer.svg';
import { ReactComponent as trailerMake } from 'assets/icons/trailer-make.svg';
import { ReactComponent as Category } from 'assets/icons/sub-categories.svg';
import { ReactComponent as List } from 'assets/icons/list-unordered.svg';
import { ReactComponent as Gallary } from 'assets/icons/gallery-view.svg';
import { ReactComponent as warning } from 'assets/icons/exclamation-triangle-filled-red.svg';
import { ReactComponent as ChangePerson } from 'assets/icons/changing-persons.svg';
import { ReactComponent as goBackArrow } from 'assets/icons/arrow-left-new.svg';
import { ReactComponent as Time } from 'assets/icons/time.svg';
import { ReactComponent as Attachment } from 'assets/icons/attachment.svg';
import { ReactComponent as Drop } from 'assets/icons/drop-circle.svg';
import { ReactComponent as Cargo } from 'assets/icons/accident-cargo.svg';
import { ReactComponent as User } from 'assets/icons/user.svg';
import { ReactComponent as Users } from 'assets/icons/users-with-frame.svg';
import { ReactComponent as Roles } from 'assets/icons/claim-with-frame.svg';
import { ReactComponent as Email } from 'assets/icons/email.svg';
import { ReactComponent as Phone } from 'assets/icons/phone.svg';
import { ReactComponent as Computer } from 'assets/icons/computer.svg';
import { ReactComponent as Check } from 'assets/icons/tick.svg';
import { ReactComponent as Clock } from 'assets/icons/clock.svg';
import { ReactComponent as Arrow } from 'assets/icons/arrow-right-new.svg';
import { ReactComponent as Info } from 'assets/icons/info.svg';
import { ReactComponent as credentials } from 'assets/icons/credentials.svg';
import { ReactComponent as maintenence } from 'assets/icons/maintenenceIcon.svg';
import { ReactComponent as maintenence_icon } from 'assets/icons/maintenance.svg';
import { ReactComponent as cardNote } from 'assets/icons/original_contract.svg';
import { ReactComponent as fuel } from 'assets/icons/fuelTank.svg';
import { ReactComponent as service } from 'assets/icons/service.svg';
import { ReactComponent as cost } from 'assets/icons/cost.svg';
import { ReactComponent as equipment_status } from 'assets/icons/equipment_status.svg';
import { ReactComponent as opposite_arrows } from 'assets/icons/opposite-arrows.svg';
import { ReactComponent as inspections } from 'assets/icons/inspection.svg';
import { ReactComponent as employee } from 'assets/icons/employee.svg';
import { ReactComponent as medical_sertificate } from 'assets/icons/medical-sertificate.svg';
import { ReactComponent as expenditure } from 'assets/icons/expenditure.svg';
import { ReactComponent as permit_icon } from 'assets/icons/permit-icon.svg';
import { ReactComponent as notification } from 'assets/icons/notification.svg';
import { ReactComponent as returned } from 'assets/icons/returned-arrow.svg';
import { ReactComponent as battery } from 'assets/icons/battery.svg';
import { ReactComponent as pressure } from 'assets/icons/pressure.svg';
import { ReactComponent as temperature } from 'assets/icons/temperature.svg';
import { ReactComponent as defLevel } from 'assets/icons/defLevel.svg';
import { ReactComponent as engineFault } from 'assets/icons/engine-fault.svg';
import { ReactComponent as drivingLimit } from 'assets/icons/driving-limit.svg';
import { ReactComponent as rest } from 'assets/icons/rest.svg';
import { ReactComponent as mobilePhone } from 'assets/icons/smartphone-line.svg';
import { ReactComponent as play } from 'assets/icons/play-fill.svg';
import { ReactComponent as pause } from 'assets/icons/pause-fill.svg';
import { ReactComponent as trailer_details } from 'assets/icons/trailer-sub.svg';
import { ReactComponent as trailer_tire } from 'assets/icons/tire.svg';
import { ReactComponent as trailer_tire_vertical } from 'assets/icons/tire-vertical.svg';
import { ReactComponent as trailer_brake } from 'assets/icons/brake.svg';
import { ReactComponent as ascIcon } from 'assets/icons/asc-icon.svg';
import { ReactComponent as descIcon } from 'assets/icons/arrow-bottom-filled.svg';
import { ReactComponent as day } from 'assets/icons/day-sun.svg';
import { ReactComponent as night } from 'assets/icons/night-moon.svg';
import { ReactComponent as EyeSlash } from 'assets/icons/eye-slash.svg';
import { ReactComponent as Eye } from 'assets/icons/eye.svg';
import { ReactComponent as notFound } from 'assets/icons/notFound.svg';
import { ReactComponent as Copy } from 'assets/icons/copy.svg';
import { ReactComponent as Generator } from 'assets/icons/password-generator.svg';
import { ReactComponent as UserAvatar } from 'assets/icons/user-avatar.svg';
import { ReactComponent as Warning } from 'assets/icons/exclamation-circle-outlined.svg';
import { ReactComponent as Telegram } from 'assets/icons/telegram.svg';
import { ReactComponent as At } from 'assets/icons/at-line.svg';
import { ReactComponent as Sending } from 'assets/icons/sending-message.svg';
import { ReactComponent as Checked } from 'assets/icons/checked-rectangle-filled.svg';
import { ReactComponent as Cleaning } from 'assets/icons/cleaning.svg';
import { ReactComponent as Equipment } from 'assets/icons/equipment.svg';
import { ReactComponent as Insurance } from 'assets/icons/insurance.svg';
import { ReactComponent as Billing } from 'assets/icons/billing.svg';
import { ReactComponent as BaseStation } from 'assets/icons/base-station-line.svg';
import { ReactComponent as rect } from 'assets/icons/Rectangle.svg';
import { ReactComponent as FileWarning } from 'assets/icons/fileWarning.svg';
import { ReactComponent as Replace } from 'assets/icons/repeat-line.svg';
import { ReactComponent as api } from 'assets/icons/api.svg';
import { ReactComponent as fileBlue } from 'assets/icons/file-text-line.svg';
import { ReactComponent as Menu } from 'assets/icons/menu-line.svg';
import { ReactComponent as Circle } from 'assets/icons/circle-filled.svg';
import { ReactComponent as Alert } from 'assets/icons/pm-info.svg';
import { ReactComponent as HosStatus } from 'assets/icons/hos_status.svg';
import { ReactComponent as Location } from 'assets/icons/location-mark-in.svg';
import { ReactComponent as edIcon } from 'assets/icons/direction-curved.svg';
import { ReactComponent as arrowRightLine } from 'assets/icons/arrow-right-line.svg';
import { ReactComponent as dots } from 'assets/icons/dots.svg';
import { ReactComponent as logo } from 'assets/icons/logi-colored-lighter.svg';
import { ReactComponent as Ricon } from 'assets/icons/registered-line.svg';
import { ReactComponent as GPS } from 'assets/icons/gps-line.svg';
import { ReactComponent as ColorPalette } from 'assets/icons/paint-fill.svg';
import { ReactComponent as NoteView } from 'assets/icons/sticky-note-line.svg';
import { ReactComponent as SidebarBurger } from 'assets/icons/sidebar-burger.svg';
import { ReactComponent as drirectionLocation } from 'assets/icons/pin-distance-line.svg';
import { ReactComponent as activeLoad } from 'assets/icons/active-load.svg';
import { ReactComponent as feedback } from 'assets/icons/bug-line.svg';
import { ReactComponent as externalLink } from 'assets/icons/external-link-line.svg';
import { ReactComponent as save } from 'assets/icons/save-line.svg';
import { ReactComponent as uzb } from 'assets/icons/uzb-flag.svg';
import { ReactComponent as us } from 'assets/icons/us-flag.svg';
import { ReactComponent as userIcon } from 'assets/icons/user-icon.svg';
import { ReactComponent as loadIcon } from 'assets/icons/loads.svg';
import { ReactComponent as hr } from 'assets/icons/hr.svg';
import { ReactComponent as requiter } from 'assets/icons/requiter.svg';
import { ReactComponent as exclamation } from 'assets/icons/exclamation.svg';
import { ReactComponent as check_circle } from 'assets/icons/check-circle.svg';
import { ReactComponent as user_not_allowed } from 'assets/icons/user-not-allowed.svg';
import { ReactComponent as alert_in_triangle } from 'assets/icons/alert-in-triangle.svg';
import { ReactComponent as filter_in_rectangle } from 'assets/icons/filter-rectangle.svg';
import { ReactComponent as note_new } from 'assets/icons/note-new.svg';
import { ReactComponent as file_check } from 'assets/icons/file-check.svg';
import { ReactComponent as send_pilot } from 'assets/icons/send-msg-pilot.svg';
import { ReactComponent as upload_cloud } from 'assets/icons/upload-cloud.svg';
import { ReactComponent as forward_arrow } from 'assets/icons/forward-msg.svg';
import { ReactComponent as server_icon } from 'assets/icons/server.svg';

const TagYes = styled(check_circle)`
  width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
`;
const TagNo = styled(exclamation)`
  width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
  path {
    stroke: ${({ color }) => color} !important;
  }
`;
const UzbFlagIcon = styled(uzb)`
  width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
`;
const UsFlagIcon = styled(us)`
  width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
`;
const SaveIcon = styled(save)`
  width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};

  cursor: pointer;
`;
const FeedbackIcon = styled(feedback)`
  width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};

  cursor: pointer;
`;
const ActiveLoadIcon = styled(activeLoad)`
  width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};

  cursor: pointer;
`;
const DirectionIcon = styled(drirectionLocation)`
  width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
  path {
    fill: ${({ color }) => color || 'var(--appTableCollapseHeaderColor)'};
  }
  cursor: pointer;
`;
const NoteViewIcon = styled(NoteView)`
  width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
  path {
    fill: ${({ color }) => color || 'var(--appTableCollapseHeaderColor)'};
  }
  cursor: pointer;
`;
const ColoredLogo = styled(logo)`
  height: inherit;
  width: 100%;
  margin-left: 5px;
`;
const ArrowRightLineIcon = styled(arrowRightLine)`
  width: 18px;
  margin: 0 10px;
  height: 18px;
  min-width: 18px;
  path {
    fill: #72768e;
  }
`;
const RequestIcon = styled(Ricon)`
  width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
  path {
    fill: ${({ color }) => color || 'var(--appTableCollapseHeaderColor)'};
  }
  margin: ${({ margin }) => (margin ? margin : '0 5px 0 0')};
`;
const GpsIcon = styled(GPS)`
  width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
  path {
    fill: ${({ color }) => color || 'var(--appTableCollapseHeaderColor)'};
  }
  margin: ${({ margin }) => (margin ? margin : '0 5px 0 0')};
`;
const EdIcon = styled(edIcon)`
  width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
  path {
    fill: ${({ color }) => color || 'var(--appTableCollapseHeaderColor)'};
  }
  margin: ${({ margin }) => (margin ? margin : '0 5px 0 0')};
`;

const LocationIcon2 = styled(Location)`
  width: ${({ larg }) => (larg === 'true' ? '20px' : '14px')};
  margin: 0 5px 0 0;
  height: ${({ larg }) => (larg === 'true' ? '20px' : '14px')};
  min-width: ${({ larg }) => (larg === 'true' ? '20px' : '14px')};
  path {
    fill: ${({ larg }) => (larg === 'true' ? '#1D4ED8' : '#72768E')};
  }
`;
const RectangleIcon = styled(rect)`
  width: 12px;
  height: 12px;
  max-width: 12px;
  min-width: 12px;
  rect {
    height: 100%;
    width: 100%;
    fill: ${({ isLoaded }) =>
      isLoaded === 'LOADED'
        ? '#000'
        : isLoaded === 'EMPTY'
        ? '#fff'
        : 'transparent'};
  }
`;

const BaseStationIcon = styled(BaseStation)`
  width: var(--appMainIconSize);
`;

export const GeneratorIcon = styled(Generator)`
  width: 20px;
  height: 20px;
  path {
    fill: #fff;
  }
`;
const ApiIcon = styled(api)`
  width: 16px;
  height: 16px;
  ${
    '' /* path {
    fill: ${({ active }) => (active ? '#2563EB' : '#4B5563')};
  } */
  }
  path {
    stroke: ${({ active }) => (active ? '#2563EB' : '#4B5563')};
  }
  &:hover {
    path {
      stroke: #2563eb;
    }
  }
`;
const FileIcon = styled(fileBlue)`
  width: 16px;
  height: 16px;
  path {
    fill: ${({ active }) => (active ? '#2563EB' : '#4B5563')};
  }
  &:hover {
    path {
      fill: #2563eb;
    }
  }
`;

const CopyIcon = styled(Copy)`
  position: absolute;
  top: -3px;
  right: 10px;
  width: 15px;
  cursor: pointer;
  height: 40px;
  z-index: 3;
  &:hover {
    path {
      fill: ${({ copied }) => (copied ? '#fff' : '#46a9ff')};
    }
  }
  path {
    fill: ${({ copied }) => (copied ? '#fff' : '#444')};
  }
  transition: all 0.2s ease;
`;
const NotFoundIcon = styled(notFound)`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
`;

const EyeSlashIcon = styled(EyeSlash)`
  width: ${({ eye }) => (eye === 'true' ? '0px' : '15px')};
  cursor: pointer;
  height: 15px;
  transition: all 0.2s ease;
`;
const EyeIcon = styled(Eye)`
  width: ${({ eye }) => (eye === 'true' ? '15px' : '0px')} !important;
  cursor: pointer;
  height: 15px !important;
  transition: all 0.2s ease;
`;
const SunIcon = styled(day)`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  display: block;
  path {
    fill: ${({ color }) => (color ? color : '#04a6fb')};
  }
`;
const MoonIcon = styled(night)`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  display: block;
  path {
    fill: ${({ color }) => (color ? color : '#04a6fb')};
  }
`;
const AscIcon = styled(ascIcon)`
  display: block;
  width: 10px;
  height: 10px;
  cursor: pointer;
  path {
    fill: ${({ isactive }) => {
      return isactive === 'true' && '#04a6fb';
    }};
  }
  :hover {
    path {
      fill: #04a6fb;
    }
  }
`;

const DescIcon = styled(descIcon)`
  display: block;
  width: 10px;
  height: 10px;
  cursor: pointer;
  path {
    fill: ${({ isactive }) => {
      return isactive === 'true' && '#04a6fb';
    }};
  }
  :hover {
    path {
      fill: #04a6fb;
    }
  }
`;

const ReplaceIcon = styled(Replace)`
  width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
  cursor: pointer;
  margin: ${({ margin }) => margin && margin};
  position: ${({ position }) => position && position};
  top: ${({ top }) => top && top};
  transform: ${({ transform }) => transform && transform};
  left: ${({ left }) => left && left};
  right: ${({ right }) => right && right};
  path {
    fill: ${({ color }) => color || 'var(--appTableCollapseHeaderColor)'};
  }
  &:hover {
    path {
      fill: var(--appIconHoverColor);
    }
  }
`;
const IconPlay = styled(play)`
  width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
  cursor: pointer;
  margin: ${({ margin }) => margin && margin};
  position: ${({ position }) => position && position};
  top: ${({ top }) => top && top};
  transform: ${({ transform }) => transform && transform};
  left: ${({ left }) => left && left};
  right: ${({ right }) => right && right};
  path {
    fill: ${({ color }) => color || 'var(--appTableCollapseHeaderColor)'};
  }
  &:hover {
    path {
      fill: var(--appIconHoverColor);
    }
  }
`;
const IconTire = styled(trailer_tire)`
  width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
  cursor: pointer;
  margin: ${({ margin }) => margin && margin};
  z-index: ${({ zIndex }) => (zIndex ? zIndex : 99)};
  position: ${({ position }) => position && position};
  top: ${({ top }) => top && top};
  bottom: ${({ bottom }) => bottom && bottom};
  transform: ${({ transform }) => transform && transform};
  left: ${({ left }) => left && left};
  right: ${({ right }) => right && right};
  rect {
    fill: ${({ color }) => (color ? color : '#C5CCD6')};
  }
  &:hover {
    rect {
      fill: var(--appIconHoverColor);
    }
  }
`;
const IconTireVertical = styled(trailer_tire_vertical)`
  width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
  margin: ${({ margin }) => margin && margin};
  position: ${({ position }) => position && position};
  top: ${({ top }) => top && top};
  transform: ${({ transform }) => transform && transform};
  left: ${({ left }) => left && left};
  right: ${({ right }) => right && right};
  cursor: ${({ cursor }) => (cursor ? cursor : 'unset')};

  /* path {
    fill: ${({ color }) => color || 'var(--appTableCollapseHeaderColor)'};
  }
  &:hover {
    path {
      fill: var(--appIconHoverColor);
    }
  } */
`;
const IconBrake = styled(trailer_brake)`
  width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
  /* cursor: pointer; */
  margin: ${({ margin }) => margin && margin};
  z-index: ${({ zIndex }) => (zIndex ? zIndex : 99)};
  position: ${({ position }) => position && position};
  top: ${({ top }) => top && top};
  bottom: ${({ bottom }) => bottom && bottom};
  transform: ${({ transform }) => transform && transform};
  left: ${({ left }) => left && left};
  right: ${({ right }) => right && right};
  cursor: ${({ cursor }) => (cursor ? cursor : 'unset')};

  path {
    fill: ${({ color }) => (color ? color : '#818A99')};
  }
  /* &:hover {
    path {
      fill: var(--appIconHoverColor);
    }
  } */
`;
const IconTrailerDetails = styled(trailer_details)`
  width: ${({ width }) => (width ? width : '260px')};
  height: ${({ height }) => (height ? height : '160px')};
  margin: ${({ margin }) => margin && margin};
  position: ${({ position }) => position && position};
  top: ${({ top }) => top && top};
  transform: ${({ transform }) => transform && transform};
  left: ${({ left }) => left && left};
  right: ${({ right }) => right && right};
`;
const IconPause = styled(pause)`
  width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
  cursor: pointer;
  margin: ${({ margin }) => margin && margin};
  position: ${({ position }) => position && position};
  top: ${({ top }) => top && top};
  transform: ${({ transform }) => transform && transform};
  left: ${({ left }) => left && left};
  right: ${({ right }) => right && right};
  path {
    fill: ${({ color }) => color || 'var(--appTableCollapseHeaderColor)'};
  }
  &:hover {
    path {
      fill: var(--appIconHoverColor);
    }
  }
`;
const IconGoBackArrow = styled(goBackArrow)`
  width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
  cursor: pointer;
  margin: ${({ margin }) => margin && margin};
  position: ${({ position }) => position && position};
  top: ${({ top }) => top && top};
  transform: ${({ transform }) => transform && transform};
  left: ${({ left }) => left && left};
  right: ${({ right }) => right && right};
  path {
    fill: ${({ color }) => color || 'var(--appTableCollapseHeaderColor)'};
  }
  &:hover {
    path {
      fill: var(--appIconHoverColor);
    }
  }
`;

const DropIcon = styled(Drop)`
  width: var(--appTableIconSize);
  height: var(--appTableIconSize);
  cursor: pointer;
  path {
    fill: ${({ color }) => color || 'var(--appIconDeleteColor)'};
  }
`;
const TimeIcon = styled(Time)`
  position: ${({ position }) => position && position};
  top: ${({ top }) => top && top};
  right: ${({ right }) => right && right};
  left: ${({ left }) => left && left};
  background: ${({ background }) => background && background};
  border-radius: ${({ borderRadius }) => borderRadius && borderRadius};
  z-index: ${({ zIndex }) => zIndex && zIndex};
  visibility: ${({ visibility }) =>
    visibility ? visibility : 'visible'} !important;
  width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
  cursor: pointer;
  path {
    fill: ${({ color }) => color || 'var(--appIconDefaultColor)'};
  }
  &:hover {
    path {
      fill: ${({ hovercolor }) => hovercolor || 'var(--appIconEditColor)'};
    }
  }
`;
const AttachmetIcon = styled(Attachment)`
  width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
  cursor: pointer;
  path {
    fill: ${({ color }) => color || 'var(--appIconDefaultColor)'};
  }
  &:hover {
    path {
      fill: ${({ hovercolor }) => hovercolor || 'var(--appIconEditColor)'};
    }
  }
`;
const TickCircleFilledIcon = styled(TickCircleFilled)`
  width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
  path {
    fill: ${({ color }) => color || 'var(--appIconEidtColor)'};
  }
`;
const FuelTankIcon = styled(fuel)`
  width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
  path {
    fill: ${({ color }) => color || 'var(--appIconDefaultColor)'};
  }
`;
const ServiceIcon = styled(service)`
  width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
  path {
    fill: ${({ color }) => color || 'var(--appIconDefaultColor)'};
  }
  cursor: ${({ cursor }) => (cursor ? cursor : 'unset')};
`;
const CostIcon = styled(cost)`
  width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
  path {
    fill: ${({ color }) => color || 'var(--appIconDefaultColor)'};
  }
`;
const EquipmentStatusIcon = styled(equipment_status)`
  width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
  path {
    fill: ${({ color }) => color || 'var(--appIconDefaultColor)'};
  }
`;
const TruckNunberIcon = styled(TruckNumber)`
  width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
  path {
    fill: ${({ color }) => color || 'var(--appIconEidtColor)'};
  }
`;
const StrikeCodeIcon = styled(TruckNumber)`
  width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
  path {
    fill: ${({ color }) => color || 'var(--appIconEidtColor)'};
  }
`;
const LightningIcon = styled(Lightning)`
  width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
  path {
    fill: ${({ color }) => color || 'var(--appIconEidtColor)'};
  }
`;
const StatusIcon = styled(Status)`
  width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
  path {
    fill: ${({ color }) => color || 'var(--appIconEidtColor)'};
  }
`;
const CalendarRoundedIcon = styled(Year)`
  width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
  path {
    fill: ${({ color }) => color || 'var(--appIconEidtColor)'};
  }
`;
const CalendarIcon = styled(TruckYear)`
  width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
  path {
    fill: ${({ color }) => color || 'var(--appIconEidtColor)'};
  }
  margin: ${({ margin }) => margin && margin};
`;
const MobileIcon = styled(mobilePhone)`
  width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
  path {
    fill: ${({ color }) => color || 'var(--appIconEidtColor)'};
  }
  margin: ${({ margin }) => margin && margin};
`;
const KeySettingIcon = styled(Make)`
  width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
  path {
    fill: ${({ color }) => color || 'var(--appIconEidtColor)'};
  }
`;
const VendorIcon = styled(Vendor)`
  width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
  path {
    fill: ${({ color }) => color || 'var(--appIconEidtColor)'};
  }
`;
const PlateStateIcon = styled(plate_state)`
  width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
  path {
    fill: ${({ color }) => color || 'var(--appIconEidtColor)'};
  }
`;
const KeyIcon = styled(Key)`
  width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
  path {
    fill: ${({ color }) => color || 'var(--appIconEidtColor)'};
  }
`;
const OwnerShipIcon = styled(OwnerShip)`
  width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
  path {
    fill: ${({ color }) => color || 'var(--appIconEidtColor)'};
  }
`;
const LicenseIcon = styled(License)`
  width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
  path {
    fill: ${({ color }) => color || 'var(--appIconEidtColor)'};
  }
`;
const ColorBottleIcon = styled(TruckColor)`
  width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
  path {
    fill: ${({ color }) => color || 'var(--appIconEidtColor)'};
  }
`;
const TruckplateIcon = styled(plateNumber)`
  width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
  path {
    fill: ${({ color }) => color || 'var(--appIconEidtColor)'};
  }
`;
const CompanyIcon = styled(Company)`
  width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
  path {
    fill: ${({ color }) => color || '#72768E' || 'var(--appIconEidtColor)'};
  }
`;
const DrugTestIcon = styled(drug_test)`
  width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
  path {
    stroke: ${({ color }) => color};
  }
`;
const NotRequiredIcon = styled(not_required)`
  width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
  path {
    stroke: ${({ color }) => color};
  }
`;
const OdometerIcon = styled(Odometer)`
  width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
  path {
    fill: ${({ color }) => color || 'var(--appIconEidtColor)'};
  }
`;
const TrailerIcon = styled(trailer)`
  width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
  path {
    stroke: ${({ color }) => color || 'var(--appIconEidtColor)'};
  }
`;
const TrailerMake = styled(trailerMake)`
  width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
  path {
    fill: ${({ color }) => color || 'var(--appIconEidtColor)'};
  }
`;
const CategoryIcon = styled(Category)`
  width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
  cursor: pointer;
`;

const IconWarning = styled(warning)`
  width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
  cursor: pointer;
  margin: ${({ margin }) => margin && margin};
  position: ${({ position }) => position && position};
  top: ${({ top }) => top && top};
  transform: ${({ transform }) => transform && transform};
  left: ${({ left }) => left && left};
  right: ${({ right }) => right && right};
  path {
    stroke: ${({ color }) => color || 'var(--appTableCollapseHeaderColor)'};
  }
  /* &:hover {
    path {
      fill: var(--appIconHoverColor);
    }
  } */
`;

const IconView = styled(Eye)`
  width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
  cursor: pointer;
  margin: ${({ margin }) => margin && margin};
  position: ${({ position }) => position && position};
  top: ${({ top }) => top && top};
  transform: ${({ transform }) => transform && transform};
  left: ${({ left }) => left && left};
  right: ${({ right }) => right && right};
  path {
    fill: ${({ color }) => color || '#fff'};
  }
  &:hover {
    path {
      fill: var(--appIconHoverColor);
    }
  }
`;

const IconGallery = styled(Gallary)`
  width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
  cursor: pointer;
  margin: ${({ margin }) => margin && margin};
  position: ${({ position }) => position && position};
  top: ${({ top }) => top && top};
  transform: ${({ transform }) => transform && transform};
  left: ${({ left }) => left && left};
  right: ${({ right }) => right && right};
  path {
    fill: ${({ color }) => color || 'var(--appIconDefaultColor)'};
  }
  &:hover {
    path {
      fill: var(--appIconHoverColor);
    }
  }
`;

const IconList = styled(List)`
  width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
  cursor: pointer;
  margin: ${({ margin }) => margin && margin};
  position: ${({ position }) => position && position};
  top: ${({ top }) => top && top};
  transform: ${({ transform }) => transform && transform};
  left: ${({ left }) => left && left};
  right: ${({ right }) => right && right};
  path {
    fill: ${({ color }) => color || 'var(--appIconDefaultColor)'};
  }
  &:hover {
    path {
      fill: var(--appIconHoverColor);
    }
  }
`;

const SearchIcon = styled(Search)`
  width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
  cursor: pointer;
  position: ${({ position }) => position && position};
  top: ${({ top }) => top && top};
  transform: ${({ transform }) => transform && transform};
  left: ${({ left }) => left && left};
  path {
    fill: ${({ color }) => color || 'var(--appIconDefaultColor)'};
  }
  &:hover {
    path {
      fill: var(--appIconHoverColor);
    }
  }
`;

const ErrorCircleIconFilled = styled(Error)`
  min-width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  min-height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
  max-width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  max-height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
  cursor: pointer;
  margin: ${({ margin }) => margin && margin};
  position: ${({ position }) => position && position};
  top: ${({ top }) => top && top};
  transform: ${({ transform }) => transform && transform};
  left: ${({ left }) => left && left};
  right: ${({ right }) => right && right};
  path {
    fill: ${({ color }) => color || 'var(--appIconDefaultColor)'};
  }
`;
const ErrorIcon = styled(Error)`
  position: absolute;
  right: 5px;
  top: 25px;
`;
const ErrorValidationIcon = styled(Error)`
  width: 16px;
  height: 16px;
  position: absolute;
  right: 5px;
  top: 28px;
`;

const DotsIcon = styled(dots)``;
const TickIcon = styled(tickIcon)`
  width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
  margin: ${({ margin }) => margin && margin};
  cursor: pointer;
  path {
    stroke: ${({ color }) => color || 'var(--appIconDefaultColor)'};
  }
  &:hover {
    path {
      stroke: ${({ color }) => color || 'var(--appIconDefaultColor)'};
    }
  }
`;

const Incomplete = styled(incomplete)`
  width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
  margin: ${({ margin }) => margin && margin};
  cursor: pointer;
  path {
    fill: ${({ color }) => color || 'var(--appIconDefaultColor)'};
  }
  &:hover {
    path {
      fill: ${({ color }) => color || 'var(--appIconDefaultColor)'};
    }
  }
`;
const FilterIcon = styled(Filter)`
  width: ${({ width }) =>
    width ? width : '12px' || 'var(--appTableIconSize)'};
  height: ${({ height }) =>
    height ? height : '12px' || 'var(--appTableIconSize)'};
  cursor: pointer;
  path {
    stroke: ${({ color }) => color || 'var(--appIconDefaultColor)'};
  }
  /* &:hover {
    path {
      fill: var(--appIconHoverColor);
    }
  } */
`;
const MenuIcon = styled(Menu)`
  width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
  cursor: pointer;
  path {
    fill: ${({ color }) => color || 'var(--appIconDefaultColor)'};
  }
`;
const CircleIcon = styled(Circle)`
  width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
  cursor: pointer;
  path {
    fill: ${({ color }) => color || 'var(--appIconDefaultColor)'};
    stroke: ${({ color }) => color || 'var(--appIconDefaultColor)'};
  }
`;
const PlusIcon = styled(Plus)`
  width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
  cursor: pointer;
  path {
    fill: ${({ color }) => color || 'var(--appIconDefaultColor)'};
  }
  &:hover {
    path {
      fill: ${({ color }) => color || 'var(--appIconDefaultColor)'};
    }
  }
`;
const MinusIcon = styled(Minus)`
  width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
  margin: ${({ margin }) => margin && margin};
  cursor: pointer;
  path {
    fill: ${({ color }) => color || 'var(--appIconDefaultColor)'};
  }
  &:hover {
    path {
      fill: ${({ color }) => color || 'var(--appIconDefaultColor)'};
    }
  }
`;
const DownloadIcon = styled(Download)`
  width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
  margin: ${({ margin }) => margin && margin};

  cursor: pointer;
  path {
    fill: ${({ color }) => color || 'var(--appTableCollapseHeaderColor)'};
  }
  &:hover {
    path {
      fill: var(--appIconHoverColor);
    }
  }
`;
const UploadIcon = styled(Upload)`
  width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
  margin: ${({ margin }) => margin && margin};

  cursor: pointer;
  path {
    fill: ${({ color }) => color || 'var(--appIconDefaultColor)'};
  }
  &:hover {
    path {
      fill: ${({ hovercolor }) => hovercolor || 'var(--appIconHoverColor)'};
    }
  }
`;
const DeleteIcon = styled(Delete)`
  width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
  cursor: pointer;
  path {
    fill: ${({ color }) => color || 'var(--appIconDefaultColor)'};
  }
  &:hover {
    path {
      fill: var(--appIconDeleteColor);
    }
  }
`;
const EditIcon = styled(Edit)`
  width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
  cursor: pointer;
  margin: ${({ margin }) => margin && margin};
  path {
    fill: ${({ color }) => color || 'var(--appIconDefaultColor)'};
  }
  &:hover {
    path {
      fill: ${({ hovercolor }) => hovercolor || 'var(--appIconEditColor)'};
    }
  }
`;
const PasswordEditIcon = styled(Edit)`
  width: ${({ eye }) => (eye === 'true' ? '0px' : '15px')};
  cursor: pointer;
  height: 40px;
  transition: all 0.2s ease;
`;
const CloseIcon = styled(Close)`
  width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
  cursor: pointer;
  position: ${({ position }) => position && position};
  top: ${({ top }) => top && top};
  transform: ${({ transform }) => transform && transform};
  left: ${({ left }) => left && left};
  right: ${({ right }) => right && right};
  bottom: ${({ bottom }) => bottom && bottom};
  path {
    fill: ${({ color }) => color || 'var(--appIconDefaultColor)'};
  }
  &:hover {
    path {
      fill: var(--appIconDeleteColor);
    }
  }
`;
const DraftIcon = styled(Draft)`
  width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
  cursor: pointer;
  position: ${({ position }) => position && position};
  top: ${({ top }) => top && top};
  transform: ${({ transform }) => transform && transform};
  left: ${({ left }) => left && left};
  path {
    fill: ${({ color }) => color || 'var(--appIconDefaultColor)'};
  }
  &:hover {
    path {
      fill: ${({ hovercolor }) => hovercolor || 'var(--appIconDefaultColor)'};
    }
  }
`;
const ArrowDouble = styled(ArrowLeftDoubleLL)`
  width: ${({ width }) => width || 'var(--appTableIconSize)'};
  height: ${({ height }) => height || 'var(--appTableIconSize)'};
  cursor: pointer;
  position: ${({ position }) => position || 'static'};
  top: ${({ top }) => top || 'auto'};
  left: ${({ left }) => left || 'auto'};
  transform: ${({ transform, active, open }) =>
    transform || (active || open ? 'rotate(90deg)' : 'rotate(270deg)')};
  path {
    fill: ${({ color }) => color || 'var(--appIconDefaultColor)'};
  }
  &:hover {
    path {
      fill: ${({ hovercolor }) => hovercolor || 'var(--appIconDefaultColor)'};
    }
  }
`;
const ArrowLeftDouble = styled(ArrowLeftDoubleLL)`
  width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
  cursor: pointer;
  path {
    fill: ${({ color }) => color || 'var(--appIconDefaultColor)'};
  }
  &:hover {
    path {
      fill: ${({ hovercolor }) => hovercolor || 'var(--appIconDefaultColor)'};
    }
  }
  margin: ${({ margin }) => margin && margin};
`;
const ArrowLeftSingle = styled(ArrowLeftSingleLL)`
  width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
  cursor: pointer;
  margin: ${({ margin }) => margin && margin};

  path {
    fill: ${({ color }) => color || 'var(--appIconDefaultColor)'};
  }
  &:hover {
    path {
      fill: ${({ hovercolor }) => hovercolor || 'var(--appIconDefaultColor)'};
    }
  }
`;
const ArrowRightDouble = styled(ArrowRightDoubleRR)`
  width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
  cursor: pointer;
  path {
    fill: ${({ color }) => color || 'var(--appIconDefaultColor)'};
  }
  &:hover {
    path {
      fill: ${({ hovercolor }) => hovercolor || 'var(--appIconDefaultColor)'};
    }
  }
  margin: ${({ margin }) => margin && margin};
`;
const ArrowRightSingle = styled(ArrowRightSingleRR)`
  width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
  cursor: pointer;
  margin: ${({ margin }) => margin && margin};
  path {
    fill: ${({ color }) => color || 'var(--appIconDefaultColor)'};
  }
  &:hover {
    path {
      fill: ${({ hovercolor }) => hovercolor || 'var(--appIconDefaultColor)'};
    }
  }
`;
const ThreeDotIcon = styled(ThreeDot)`
  width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  min-width: ${({ minwidth }) =>
    minwidth ? minwidth : 'var(--appTableIconSize)'};
  height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
  min-height: ${({ minheight }) =>
    minheight ? minheight : 'var(--appTableIconSize)'};
  cursor: pointer;
  position: ${({ position }) => position && position};
  top: ${({ top }) => top && top};
  transform: ${({ open }) => (open ? 'rotate(90deg)' : 'rotate(0deg)')};
  left: ${({ left }) => left && left};
  path {
    fill: ${({ color }) => color || 'var(--appIconDefaultColor)'};
  }
  &:hover {
    path {
      fill: ${({ hovercolor }) => hovercolor || 'var(--appIconDefaultColor)'};
    }
  }
`;

export const TruckIcon = styled(Truck)`
  width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  min-width: ${({ minwidth }) =>
    minwidth ? minwidth : 'var(--appTableIconSize)'};
  height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
  min-height: ${({ minheight }) =>
    minheight ? minheight : 'var(--appTableIconSize)'};
  cursor: pointer;
  position: ${({ position }) => position && position};
  top: ${({ top }) => top && top};
  transform: ${({ transform }) => transform && transform};
  left: ${({ left }) => left && left};
  path {
    stroke: ${({ color }) => color || 'var(--appIconDefaultColor)'};
  }
  &:hover {
    path {
      stroke: ${({ hovercolor }) => hovercolor || 'var(--appIconDefaultColor)'};
    }
  }
`;
export const TrailerModelIcon = styled(TrailerModel)`
  width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  min-width: ${({ minwidth }) =>
    minwidth ? minwidth : 'var(--appTableIconSize)'};
  height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
  min-height: ${({ minheight }) =>
    minheight ? minheight : 'var(--appTableIconSize)'};
  cursor: pointer;
  position: ${({ position }) => position && position};
  top: ${({ top }) => top && top};
  transform: ${({ transform }) => transform && transform};
  left: ${({ left }) => left && left};
  path {
    fill: ${({ color }) => color || 'var(--appIconDefaultColor)'};
  }
  &:hover {
    path {
      fill: ${({ color }) => color || 'var(--appIconDefaultColor)'};
    }
  }
`;
export const ArrowSLineIcon = styled(ArrowSLine)`
  width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  min-width: ${({ minwidth }) =>
    minwidth ? minwidth : 'var(--appTableIconSize)'};
  height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
  min-height: ${({ minheight }) =>
    minheight ? minheight : 'var(--appTableIconSize)'};
  cursor: pointer;
  position: ${({ position }) => position && position};
  top: ${({ top }) => top && top};
  transform: ${({ transform }) => transform && transform};
  left: ${({ left }) => left && left};
  path {
    fill: ${({ color }) => color || 'var(--appIconDefaultColor)'};
  }
  &:hover {
    path {
      fill: ${({ hovercolor }) => hovercolor || 'var(--appIconDefaultColor)'};
    }
  }
`;
const ArrowDownIcon = styled(ArrowDown)`
  width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  min-width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
  min-height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
  cursor: pointer;
  position: ${({ position }) => position && position};
  top: ${({ top }) => top && top};
  transform: ${({ open }) => (open ? 'rotate(180deg)' : 'rotate(0deg)')};
  left: ${({ left }) => left && left};
  path {
    fill: ${({ color }) => color || 'var(--appIconDefaultColor)'};
    /* stroke: #fff */
  }
  &:hover {
    path {
      fill: ${({ hovercolor, disabled }) =>
        !disabled && (hovercolor || 'var(--appIconDefaultColor)')};
    }
  }
`;
const ArrowUpIcon = styled(ArrowUp)`
  width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  min-width: ${({ minwidth }) =>
    minwidth ? minwidth : 'var(--appTableIconSize)'};
  height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
  min-height: ${({ minheight }) =>
    minheight ? minheight : 'var(--appTableIconSize)'};
  cursor: pointer;
  position: ${({ position }) => position && position};
  top: ${({ top }) => top && top};
  transform: ${({ transform }) => transform && transform};
  left: ${({ left }) => left && left};
  path {
    fill: ${({ color }) => color || 'var(--appIconDefaultColor)'};
  }
  &:hover {
    path {
      fill: ${({ hovercolor, disabled }) =>
        !disabled && (hovercolor || 'var(--appIconDefaultColor)')};
    }
  }
`;
const ArrowDownLineIcon = styled(ArrowDownLine)`
  width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  min-width: ${({ minwidth }) =>
    minwidth ? minwidth : 'var(--appTableIconSize)'};
  height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
  min-height: ${({ minheight }) =>
    minheight ? minheight : 'var(--appTableIconSize)'};
  cursor: pointer;
  position: ${({ position }) => position && position};
  top: ${({ top }) => top && top};
  transform: ${({ transform }) => transform && transform};
  left: ${({ left }) => left && left};
  transform: ${({ isactive }) => {
    return isactive === 'true' ? 'rotate(180deg)' : 'rotate(0deg)';
  }};
  path {
    fill: ${({ color }) => color || 'var(--appIconDefaultColor)'};
  }
  &:hover {
    path {
      fill: ${({ hovercolor }) => hovercolor || 'var(--appIconDefaultColor)'};
    }
  }
`;

const RefreshIcon = styled(Refresh)`
  width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
  cursor: pointer;
  position: ${({ position }) => position && position};
  top: ${({ top }) => top && top};
  ${'' /* transform: ${({ transform }) => transform && transform}; */}
  left: ${({ left }) => left && left};
  path {
    fill: ${({ color }) => color || 'var(--appIconDefaultColor)'};
  }
  &:hover {
    path {
      fill: ${({ hovercolor }) => hovercolor || 'var(--appIconDefaultColor)'};
    }
  }
  animation: ${({ is_loading }) =>
    is_loading === 'true' ? 'rotate 0.3s infinite' : null};

  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    25% {
      transform: rotate(90deg);
    }
    50% {
      transform: rotate(180deg);
    }
    75% {
      transform: rotate(270deg);
    }
    100% {
      transform: rotate(359deg);
    }
  }
`;
const TopBarMapIcon = styled(Map)`
  width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
  cursor: pointer;
  position: ${({ position }) => position && position};
  top: ${({ top }) => top && top};
  transform: ${({ transform }) => transform && transform};
  left: ${({ left }) => left && left};
  path {
    stroke: ${({ color }) => color || 'var(--appIconDefaultColor)'};
  }
  &:hover {
    path {
      stroke: ${({ color }) => color || 'var(--appIconDefaultColor)'};
    }
  }
`;

const ZoneHeaderIcon = styled(zoneHeaderIcon)`
  width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
  cursor: pointer;
  position: ${({ position }) => position && position};
  top: ${({ top }) => top && top};
  transform: ${({ transform }) => transform && transform};
  left: ${({ left }) => left && left};
  path {
    fill: ${({ color }) => color || 'var(--appIconDefaultColor)'};
  }
  &:hover {
    path {
      fill: var(--appIconHoverColor);
    }
  }
`;

const IconNote = styled(Note)`
  width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
  cursor: pointer;
  position: ${({ position }) => position && position};
  top: ${({ top }) => top && top};
  transform: ${({ transform }) => transform && transform};
  left: ${({ left }) => left && left};
  path {
    fill: ${({ color }) => color || 'var(--appIconDefaultColor)'};
  }
  &:hover {
    path {
      fill: var(--appIconHoverColor);
    }
  }
`;

const LocationIcon = styled(location)`
  width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
  margin: ${({ margin }) => margin && margin};
  path {
    fill: ${({ color }) => color || 'var(--appIconEditColor)'};
  }
`;
const IconWiFiOn = styled(WiFiOn)`
  width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
  path {
    fill: ${({ color }) => color || 'var(--appIconDefaultColor)'};
  }
`;
const IconWiFiError = styled(WiFiError)`
  width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
  path {
    fill: ${({ color }) => color || 'var(--appIconDefaultColor)'};
  }
`;
const IconStricker = styled(StickerMain)`
  width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
  path {
    stroke: ${({ color }) => color || 'var(--appIconDefaultColor)'};
  }
`;
const ChangePersonIcon = styled(ChangePerson)`
  width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
  path {
    stroke: ${({ color }) => color || 'var(--appIconDefaultColor)'};
  }
`;
const CargoIcon = styled(Cargo)`
  width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
  path {
    stroke: ${({ color }) => color || 'var(--appIconDefaultColor)'};
  }
`;
const UserIcon = styled(User)`
  width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
  path {
    fill: ${({ color }) => color || 'var(--appIconEditColor)'};
  }
`;
const UsersIcon = styled(Users)`
  width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
  path {
    fill: ${({ color }) => color || 'var(--appIconEditColor)'};
  }
`;
const RolesIcon = styled(Roles)`
  width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
  path {
    fill: ${({ color }) => color || 'var(--appIconEditColor)'};
  }
`;
const EmailIcon = styled(Email)`
  width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
  path {
    fill: ${({ color }) => color || 'var(--appIconEditColor)'};
  }
`;
const PhoneIcon = styled(Phone)`
  width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
  path {
    fill: ${({ color }) => color || 'var(--appIconEditColor)'};
  }
`;
const ComputerIcon = styled(Computer)`
  width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
  path {
    fill: ${({ color }) => color || 'var(--appIconEditColor)'};
  }
`;
const CheckIcon = styled(Check)`
  width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
  cursor: pointer;
  path {
    fill: ${({ color }) => color || 'var(--appIconEditColor)'};
  }
`;
const CheckPasswordIcon = styled(Check)`
  width: ${({ eye }) => (eye === 'true' ? '20px' : '0px')};
  cursor: pointer;
  margin-right: -3px;
  path:last-child {
    stroke: green;
  }
  height: 40px;
  transition: all 0.2s ease;
`;
const ClockIcon = styled(Clock)`
  margin: ${({ margin }) => (margin ? margin : 0)};
  width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
  path {
    fill: ${({ color }) => color || 'var(--appIconEditColor)'};
  }
`;
const ArrowIcon = styled(Arrow)`
  width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
  path {
    stroke: ${({ color }) => color || 'var(--appIconEditColor)'};
  }
`;

const InfoIcon = styled(Info)`
  width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
  cursor: pointer;
  margin: ${({ margin }) => margin && margin};
  position: ${({ position }) => position && position};
  top: ${({ top }) => top && top};
  bottom: ${({ bottom }) => bottom && bottom};
  ${'' /* transform: ${({ transform }) => transform && transform}; */}
  left: ${({ left }) => left && left};
  right: ${({ right }) => right && right};
  path {
    fill: ${({ color }) => color || 'var(--appTableCollapseHeaderColor)'};
  }
  &:hover {
    path {
      fill: ${({ noHoverOver, color }) =>
        noHoverOver ? color : 'var(--appIconHoverColor)'};
    }
  }
`;
const InfoIcon2 = styled(Info)`
  width: 40px;
  height: 40px;
  path {
    fill: ${({ color }) => color || 'var(--appTableCollapseHeaderColor)'};
  }
`;
const CredentialsIcons = styled(credentials)`
  width: 40px;
  height: 40px;
  path {
    fill: ${({ color }) => color || 'var(--appTableCollapseHeaderColor)'};
  }
`;

const MaintenenceIcon = styled(maintenence)`
  width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
  cursor: pointer;
  margin: ${({ margin }) => margin && margin};

  path {
    fill: ${({ color }) => color || 'var(--appTableCollapseHeaderColor)'};
  }
  &:hover {
    path {
      fill: var(--appIconHoverColor);
    }
  }
`;

const MovementIcon = styled(opposite_arrows)`
  width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
  cursor: pointer;
  margin: ${({ margin }) => margin && margin};

  path {
    stroke: ${({ color }) => color || 'var(--appTableCollapseHeaderColor)'};
  }
  &:hover {
    path {
      stroke: ${({ hovercolor }) => hovercolor || 'var(--appIconHoverColor)'};
    }
  }
`;
const InspectionIcon = styled(inspections)`
  width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
  cursor: pointer;
  margin: ${({ margin }) => margin && margin};

  path {
    stroke: ${({ color }) => color || 'var(--appTableCollapseHeaderColor)'};
  }
  &:hover {
    path {
      stroke: ${({ hovercolor }) => hovercolor || 'var(--appIconHoverColor)'};
    }
  }
`;
const ExpenditureIcon = styled(expenditure)`
  width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
  cursor: pointer;
  margin: ${({ margin }) => margin && margin};

  path {
    stroke: ${({ color }) => color || 'var(--appTableCollapseHeaderColor)'};
  }
`;
const EmployeeIcon = styled(employee)`
  width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
  cursor: pointer;
  margin: ${({ margin }) => margin && margin};

  path {
    stroke: ${({ color }) => color || 'var(--appTableCollapseHeaderColor)'};
  }
`;
const MedicalCertificateIcon = styled(medical_sertificate)`
  width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
  cursor: pointer;
  margin: ${({ margin }) => margin && margin};

  path {
    stroke: ${({ color }) => color || 'var(--appTableCollapseHeaderColor)'};
  }
`;

const MaintenanceIcon = styled(maintenence_icon)`
  width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
  cursor: pointer;
  margin: ${({ margin }) => margin && margin};

  path {
    stroke: ${({ color }) => color || 'var(--appTableCollapseHeaderColor)'};
  }
`;
const PermitIcon = styled(permit_icon)`
  width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
  cursor: pointer;
  margin: ${({ margin }) => margin && margin};

  path {
    fill: ${({ color }) => color || 'var(--appTableCollapseHeaderColor)'};
  }
`;
const OrgContactIcon = styled(cardNote)`
  width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
  cursor: pointer;
  margin: ${({ margin }) => margin && margin};

  path {
    stroke: ${({ color }) => color || 'var(--appTableCollapseHeaderColor)'};
  }
  circle {
    stroke: ${({ color }) => color || 'var(--appTableCollapseHeaderColor)'};
  }
`;

const CardNoteIcon = styled(cardNote)`
  width: ${({ width }) => {
    width ? width : 'var(--appTableIconSize)';
  }};
  height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
  path {
    stroke: ${({ color }) => color || 'var(--appIconEidtColor)'};
  }
  circle {
    stroke: ${({ color }) => color || 'var(--appIconEidtColor)'};
  }
  cursor: pointer;
  margin: ${({ margin }) => margin && margin};
`;

const CardNotification = styled(notification)`
  width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
  path {
    fill: ${({ color }) => color || 'var(--appIconEidtColor)'};
  }
  cursor: pointer;

  margin: ${({ margin }) => margin && margin};
`;

const ReturnedIcon = styled(returned)`
  width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
  path {
    fill: ${({ color }) => color || 'var(--appIconEidtColor)'};
  }
  cursor: pointer;

  margin: ${({ margin }) => margin && margin};
`;
const BatteryIcon = styled(battery)`
  width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
  path {
    fill: ${({ color }) => color || 'var(--appIconEidtColor)'};
  }
  /* cursor: pointer; */

  margin: ${({ margin }) => margin && margin};
`;
const PressureIcon = styled(pressure)`
  width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
  path {
    fill: ${({ color }) => color || 'var(--appIconEidtColor)'};
  }
  /* cursor: pointer; */

  margin: ${({ margin }) => margin && margin};
`;
const TemperatureIcon = styled(temperature)`
  width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
  path {
    fill: ${({ color }) => color || 'var(--appIconEidtColor)'};
  }
  /* cursor: pointer; */

  margin: ${({ margin }) => margin && margin};
`;
const DefLevelIcon = styled(defLevel)`
  width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
  /* path {
    stroke: ${({ color }) => color || 'var(--appIconEidtColor)'};
  } */
  /* cursor: pointer; */

  margin: ${({ margin }) => margin && margin};
`;
const EngineFaultIcon = styled(engineFault)`
  width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
  path {
    stroke: ${({ color }) => color || 'var(--appIconEidtColor)'};
  }
  /* cursor: pointer; */

  margin: ${({ margin }) => margin && margin};
`;
const DrivingLimitIcon = styled(drivingLimit)`
  width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
  path {
    fill: ${({ color }) => color || 'var(--appIconEidtColor)'};
  }
  &:hover {
    path {
      fill: ${({ hovercolor }) => hovercolor || 'var(--appTableCellLinkColor)'};
    }
  }
  /* cursor: pointer; */

  margin: ${({ margin }) => margin && margin};
`;
const RestIcon = styled(rest)`
  width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
  path {
    fill: ${({ color }) => color || 'var(--appIconEidtColor)'};
  }
  /* cursor: pointer; */

  margin: ${({ margin }) => margin && margin};
`;

const AvatarIcon = styled(UserAvatar)`
  width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
  path {
    fill: ${({ color }) => color || '#989ea3'};
  }
  /* cursor: pointer; */

  margin: ${({ margin }) => margin && margin};
`;

const WarningIcon = styled(Warning)`
  width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
  path {
    stroke: ${({ color }) => color || '#f26987'};
  }

  margin: ${({ margin }) => margin && margin};
`;
const TelegramIcon = styled(Telegram)`
  width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
  path {
    fill: ${({ color }) => color || '#989ea3'};
  }

  margin: ${({ margin }) => margin && margin};
`;
const AtIcon = styled(At)`
  width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
  path {
    fill: ${({ color }) => color || '#989ea3'};
  }

  margin: ${({ margin }) => margin && margin};
`;
const SendingIcon = styled(Sending)`
  width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
  path {
    fill: ${({ color }) => color || '#989ea3'};
  }

  margin: ${({ margin }) => margin && margin};
`;
const CheckedIcon = styled(Checked)`
  width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
  path {
    fill: ${({ color }) => color || '#989ea3'};
  }

  margin: ${({ margin }) => margin && margin};
`;

const CleaningIcon = styled(Cleaning)`
  width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
  cursor: pointer;
  path {
    fill: ${({ color }) => color || 'var(--appIconDefaultColor)'};
  }
  &:hover {
    path {
      fill: ${({ color }) => color || 'var(--appIconDefaultColor)'};
    }
  }
`;
const EquipmentIcon = styled(Equipment)`
  width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
  cursor: pointer;
  path {
    fill: ${({ color }) => color || 'var(--appIconDefaultColor)'};
  }
  &:hover {
    path {
      fill: ${({ color }) => color || 'var(--appIconDefaultColor)'};
    }
  }
`;
const InsuranceIcon = styled(Insurance)`
  width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
  cursor: pointer;
  path {
    fill: ${({ color }) => color || 'var(--appIconDefaultColor)'};
  }
  &:hover {
    path {
      fill: ${({ color }) => color || 'var(--appIconDefaultColor)'};
    }
  }
`;
const FileWarningIcon = styled(FileWarning)`
  min-width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  min-height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
  max-width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  max-height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
  cursor: pointer;
  path {
    fill: ${({ color }) => color || 'var(--appIconDefaultColor)'};
  }
  &:hover {
    path {
      fill: ${({ color }) => color || 'var(--appIconDefaultColor)'};
    }
  }
`;
const BillingIcon = styled(Billing)`
  width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
  cursor: pointer;
  path {
    fill: ${({ color }) => color || 'var(--appIconDefaultColor)'};
  }
  &:hover {
    path {
      fill: ${({ color }) => color || 'var(--appIconDefaultColor)'};
    }
  }
`;
const AlertIcon = styled(Alert)`
  width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
  cursor: pointer;
  path {
    fill: ${({ color }) => color || 'var(--appIconDefaultColor)'};
  }
  &:hover {
    path {
      fill: ${({ color }) => color || 'var(--appIconDefaultColor)'};
    }
  }
`;
const HosStatusIcon = styled(HosStatus)`
  width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
  cursor: pointer;
  path {
    fill: ${({ color }) => color || 'var(--appIconDefaultColor)'};
  }
  &:hover {
    path {
      fill: ${({ color }) => color || 'var(--appIconDefaultColor)'};
    }
  }
`;
const ColorPaletteIcon = styled(ColorPalette)`
  width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
  position: ${({ position }) => position && position};
  cursor: pointer;
  path {
    fill: ${({ color }) => color || 'var(--appIconDefaultColor)'};
  }
  &:hover {
    path {
      fill: ${({ color }) => color || 'var(--appIconDefaultColor)'};
    }
  }
`;
const SidebarBurgerIcon = styled(SidebarBurger)`
  width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
  transform: rotate(${({ rotate }) => (rotate ? rotate : '0deg')});
  cursor: pointer;
  path {
    fill: ${({ color }) => color || 'var(--appIconDefaultColor)'};
  }
  &:hover {
    path {
      fill: ${({ color }) => color || 'var(--appIconDefaultColor)'};
    }
  }
`;
const ExternalLinkIcon = styled(externalLink)`
  width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
  transform: rotate(${({ rotate }) => (rotate ? rotate : '0deg')});
  cursor: pointer;
  path {
    fill: ${({ color }) => color || 'var(--appIconDefaultColor)'};
  }
  &:hover {
    path {
      fill: ${({ color }) => color || 'var(--appIconDefaultColor)'};
    }
  }
`;
const UserRoundedIcon = styled(userIcon)`
  width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
  transform: rotate(${({ rotate }) => (rotate ? rotate : '0deg')});
  cursor: pointer;
  path {
    stroke: ${({ color }) => color || 'var(--appIconDefaultColor)'};
  }
  &:hover {
    path {
      stroke: ${({ color }) => color || 'var(--appIconDefaultColor)'};
    }
  }
`;
const LoadIcon = styled(loadIcon)`
  width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
  transform: rotate(${({ rotate }) => (rotate ? rotate : '0deg')});
  cursor: pointer;
  path {
    stroke: ${({ color }) => color || 'var(--appIconDefaultColor)'};
  }
  &:hover {
    path {
      stroke: ${({ color }) => color || 'var(--appIconDefaultColor)'};
    }
  }
`;
const HrIcon = styled(hr)`
  width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
  path {
    stroke: ${({ color }) => color || 'var(--appIconDefaultColor)'};
  }
  circle {
    stroke: ${({ color }) => color || 'var(--appIconDefaultColor)'};
  }
`;
const RecuiterIcon = styled(requiter)`
  width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
  path {
    stroke: ${({ color }) => color || 'var(--appIconDefaultColor)'};
  }
  circle {
    stroke: ${({ color }) => color || 'var(--appIconDefaultColor)'};
  }
`;

const UserNotAllowedIcon = styled(user_not_allowed)`
  width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
  path {
    stroke: ${({ color }) => color || 'var(--appIconDefaultColor)'};
  }
  circle {
    stroke: ${({ color }) => color || 'var(--appIconDefaultColor)'};
  }
`;
const AlertInTriangleIcon = styled(alert_in_triangle)`
  width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
  path {
    stroke: ${({ color }) => color || 'var(--appIconDefaultColor)'};
  }
  circle {
    stroke: ${({ color }) => color || 'var(--appIconDefaultColor)'};
  }
`;
const FilterInRectangleIcon = styled(filter_in_rectangle)`
  width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
  path {
    stroke: ${({ color }) => color || 'var(--appIconDefaultColor)'};
  }
  circle {
    stroke: ${({ color }) => color || 'var(--appIconDefaultColor)'};
  }
`;
const NoteNewIcon = styled(note_new)`
  width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
  path {
    stroke: ${({ color }) => color || 'var(--appIconDefaultColor)'};
  }
  circle {
    stroke: ${({ color }) => color || 'var(--appIconDefaultColor)'};
  }
`;
const FileCheckIcon = styled(file_check)`
  width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
  path {
    stroke: ${({ color }) => color || 'var(--appIconDefaultColor)'};
  }
  circle {
    stroke: ${({ color }) => color || 'var(--appIconDefaultColor)'};
  }
`;
const SendPilotIcon = styled(send_pilot)`
  width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
  path {
    stroke: ${({ color }) => color || 'var(--appIconDefaultColor)'};
  }
`;
const UploadCloudIcon = styled(upload_cloud)`
  width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
  path {
    stroke: ${({ color }) => color || 'var(--appIconDefaultColor)'};
  }
`;

const ForwardArrowIcon = styled(forward_arrow)`
  width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
  path {
    fill: ${({ color }) => color || 'var(--appIconDefaultColor)'};
  }
`;
const ServerIcon = styled(server_icon)`
  width: ${({ width }) => (width ? width : 'var(--appTableIconSize)')};
  height: ${({ height }) => (height ? height : 'var(--appTableIconSize)')};
  path {
    fill: ${({ color }) => color || 'var(--appIconDefaultColor)'};
  }
`;

export {
  BatteryIcon,
  PressureIcon,
  TemperatureIcon,
  DefLevelIcon,
  EngineFaultIcon,
  DrivingLimitIcon,
  RestIcon,
  OrgContactIcon,
  PermitIcon,
  MaintenanceIcon,
  MovementIcon,
  CardNoteIcon,
  InspectionIcon,
  MaintenenceIcon,
  InfoIcon,
  IconWiFiOn,
  IconWiFiError,
  IconStricker,
  DeleteIcon,
  EditIcon,
  CloseIcon,
  FilterIcon,
  PlusIcon,
  MinusIcon,
  DownloadIcon,
  DraftIcon,
  ArrowDouble,
  RefreshIcon,
  ThreeDotIcon,
  TopBarMapIcon,
  ZoneHeaderIcon,
  Incomplete,
  DotsIcon,
  TickIcon,
  ErrorCircleIconFilled,
  ErrorIcon,
  ErrorValidationIcon,
  LocationIcon,
  ArrowDownIcon,
  ArrowUpIcon,
  ArrowDownLineIcon,
  SearchIcon,
  UploadIcon,
  TruckNunberIcon,
  StrikeCodeIcon,
  LightningIcon,
  CalendarRoundedIcon,
  KeySettingIcon,
  PlateStateIcon,
  KeyIcon,
  LicenseIcon,
  ColorBottleIcon,
  TruckplateIcon,
  CompanyIcon,
  NotRequiredIcon,
  DrugTestIcon,
  OdometerIcon,
  TrailerIcon,
  IconList,
  IconGallery,
  IconView,
  IconWarning,
  IconGoBackArrow,
  CategoryIcon,
  ChangePersonIcon,
  TimeIcon,
  AttachmetIcon,
  IconNote,
  TickCircleFilledIcon,
  VendorIcon,
  TrailerMake,
  OwnerShipIcon,
  StatusIcon,
  CalendarIcon,
  DropIcon,
  CargoIcon,
  UserIcon,
  EmailIcon,
  PhoneIcon,
  ComputerIcon,
  CheckIcon,
  ClockIcon,
  ArrowIcon,
  FuelTankIcon,
  ArrowRightDouble,
  ArrowLeftDouble,
  ArrowLeftSingle,
  ArrowRightSingle,
  ServiceIcon,
  CostIcon,
  EquipmentStatusIcon,
  CardNotification,
  ReturnedIcon,
  MobileIcon,
  IconPlay,
  IconPause,
  UsersIcon,
  RolesIcon,
  IconTire,
  IconTireVertical,
  IconBrake,
  IconTrailerDetails,
  AscIcon,
  DescIcon,
  SunIcon,
  MoonIcon,
  EyeSlashIcon,
  EyeIcon,
  NotFoundIcon,
  CopyIcon,
  CheckPasswordIcon,
  PasswordEditIcon,
  AvatarIcon,
  WarningIcon,
  TelegramIcon,
  AtIcon,
  SendingIcon,
  CheckedIcon,
  CleaningIcon,
  EquipmentIcon,
  InsuranceIcon,
  FileWarningIcon,
  BillingIcon,
  BaseStationIcon,
  RectangleIcon,
  ReplaceIcon,
  ApiIcon,
  FileIcon,
  InfoIcon2,
  CredentialsIcons,
  MenuIcon,
  ExpenditureIcon,
  EmployeeIcon,
  MedicalCertificateIcon,
  CircleIcon,
  AlertIcon,
  HosStatusIcon,
  LocationIcon2,
  EdIcon,
  ArrowRightLineIcon,
  ColoredLogo,
  NoteViewIcon,
  RequestIcon,
  GpsIcon,
  ColorPaletteIcon,
  SidebarBurgerIcon,
  DirectionIcon,
  ActiveLoadIcon,
  FeedbackIcon,
  ExternalLinkIcon,
  SaveIcon,
  UzbFlagIcon,
  UsFlagIcon,
  UserRoundedIcon,
  LoadIcon,
  HrIcon,
  RecuiterIcon,
  TagYes,
  TagNo,
  UserNotAllowedIcon,
  AlertInTriangleIcon,
  FilterInRectangleIcon,
  NoteNewIcon,
  FileCheckIcon,
  SendPilotIcon,
  UploadCloudIcon,
  ForwardArrowIcon,
  ServerIcon,
};
