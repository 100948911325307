/** @format */

import { Collapse } from '../../AntDesign';
import styled from 'styled-components';

export const GridTable = styled.div`
  width: 100%;
  border: 1px solid #f3f4f5;
  color: #313e47;
  font-size: var(--appTableFontSize) !important;
  background: transparent;
  height: calc(100% - ${({ height }) => (height ? height : '0')}px);
  display: flex;
  flex-direction: column;
  position: relative;

  overflow-y: auto;
  &::-webkit-scrollbar {
    background-color: transparent;
    width: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgb(193, 193, 193);
    width: 10px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: rgb(168, 168, 168);
  }

  div.turncated {
    text-overflow: ellipsis;
    width: calc(98%);
    white-space: nowrap;
    overflow: hidden;
    text-align: center;
  }

  .loader-wrapper {
    position: absolute;
    inset: 0;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.5);
    pointer-events: none;
    max-height: 100vh;
  }
`;

GridTable.Resizer = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 7px;
  background: transparent;
  cursor: col-resize;
`;

GridTable.Head = styled.div`
  position: sticky;
  top: 0;
  z-index: 99;
`;
GridTable.Body = styled.div`
  flex-grow: 1;
`;

GridTable.Filter = styled.div`
  background: #f1f3f8;
  display: grid;
  grid-template-columns: ${({ gridtemplatecolumns }) => gridtemplatecolumns};
  height: 0px !important;

  &.open {
    height: 40px !important;
  }
  transition: height 0.1s;
  overflow: hidden;
`;

GridTable.Tr = styled.div`
  display: grid;
  grid-template-columns: ${({ gridtemplatecolumns }) => gridtemplatecolumns};
  height: ${({ height }) => (height ? height : '40px')};
  transition: height 0.3s;
  background: ${({ header, headerBackground }) =>
    header
      ? '#f1f3f8'
      : header && headerBackground
      ? headerBackground
      : '#fff'};
  &:hover {
    background: ${({ header }) => (header ? '#f1f3f8' : '#ecf0f1')};
  }
`;

GridTable.Td = styled.div`
  line-height: ${({ height }) => (height ? height : '40px')};
  border: 1px solid transparent;

  border-left-color: ${({ removecolborder }) =>
    removecolborder === 'true' ? 'unset' : '#e5e7eb'};
  border-bottom-color: ${({ removecolborder }) =>
    removecolborder === 'true' ? 'unset' : '#e5e7eb'};
  padding: 0 10px;
  grid-column: span ${({ colSpan }) => colSpan};
  text-align: ${({ colSpan }) => (colSpan ? 'center' : 'left')};
  height: 100%;
  outline: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  &.active-cell {
    border-color: #007aff !important;
  }
  &:hover {
    border-color: #007aff !important;
  }
  &.copied {
    animation: 1s anim linear;
    @keyframes anim {
      from {
        background: #007aff;
        color: white;
      }
      to {
        background: #fff;
        color: black;
      }
    }
  }
  .edit-cell-text {
    outline: none !important;
    width: 100%;
    min-height: 100% !important;
    border-radius: 0 !important;
    padding: 4px 10px 6px 9px !important;
    resize: none;
    font-size: 12px !important;
    line-height: 16px;
    letter-spacing: 0.28px;
    /* Firefox shows scrollbar on growth, you can hide like this. */
    overflow: hidden;
  }
`;

GridTable.Th = styled.div`
  background: ${({ background }) => (background ? background : '#f1f3f8')};
  height: 100%;
  line-height: ${({ height }) => (height ? height : '40px')};
  padding: 0 10px;
  position: relative;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  &::after {
    content: '';
    position: absolute;
    top: 2px;
    bottom: 2px;
    left: 0;
    width: 1px;
    background: #ccc;
  }
  display: ${({ filter }) => filter && 'flex'};
  align-items: center;
  justify-content: center;
`;
GridTable.CollapseHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background: #f0f6ff !important;
  height: auto;
  min-height: 30px;
  & > div {
    width: calc(100% - 31px);
  }
  /* height: ${({ height }) => height + 'px'}; */
  border: ${({ removecolborder }) =>
    removecolborder === 'true' ? 'none' : '1px solid #e5e7eb'};
  border-top-color: transparent;
  /* &.active-collapse {
    border-color: #007aff !important;
  } */
  &:focus-within:not(:focus) {
    border-color: #007aff !important;
  }
  &:focus,
  &:hover {
    border-color: #007aff !important;
  }
  .arrow {
    width: 31px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    cursor: pointer;
    &::after {
      content: '';
      position: absolute;
      top: 48%;
      left: 44%;
      width: 7px;
      height: 7px;
      border: 1px solid transparent;
      border-bottom-color: #374151;
      border-right-color: #374151;
      transform-origin: 3px 3px;
      transform: rotate(45deg) translate(-50%, -50%);
      transition: transform 0.3s;
    }
  }
`;
GridTable.Collapse = styled.div`
  background: #f0f6ff !important;
  height: fit-content;
  /* height: ${({ height, headerheight }) =>
    height ? height + headerheight + 'px' : 'auto'}; */
  overflow: hidden;
  transition: height 0.3s;
  &.close-collapse {
    height: ${({ headerheight }) => headerheight + 'px'};
    .arrow::after {
      transform: rotate(-45deg) translate(-50%, -50%);
    }
  }
`;

GridTable.Counter = styled.div`
  position: absolute;
  top: 0;
  left: 31px;
  bottom: 0;
  display: flex;
  align-items: center;
  font-size: var(--appTableCollapseHeaderCountFontSize);
`;

const { Panel } = Collapse;

export const AntPanel = styled(Panel)`
  height: auto;
  /* background-color: ${({ index }) =>
    index % 2 === 0 ? 'white' : '#f1f3f8'}; */
  /* font-size: var(--appTableFontSize);
  color: #313e47; */
`;

export const AntCollapse = styled(Collapse)`
  min-width: 900px;
  border: none !important;
  div.ant-collapse-item {
    display: flex;
    flex: 1;
    flex-direction: column;
    border: none !important;
    div.ant-collapse-content {
      border-top: none !important;
      div.ant-collapse-content-box {
        padding: 0 !important;
      }
    }
    div.ant-collapse-header {
      svg {
        fill: black;
      }
      display: flex;
      align-items: center;
      padding: 0 !important;
      height: fit-content;
      /* height: ${({ rowHeight }) => rowHeight} !important; */
      > div {
        color: #313e47 !important;

        font-family: var(--appPrimaryFont) !important;
        font-style: normal !important;
        font-weight: normal !important;
        font-size: var(--appTableFontSize) !important;
        line-height: 18px !important;
        align-items: center !important;
        div {
          div {
            div:last-child {
              font-size: 13px !important;
            }
          }
        }
      }
      span.ant-collapse-header-text {
        overflow-x: auto !important ;
      }
    }
  }
  div.listheader {
    div.ant-collapse-header {
      svg {
        fill: transparent;
      }
    }
    div {
      cursor: default !important;
    }
  }
  &:where(
      .css-dev-only-do-not-override-p7e5j5
    ).ant-collapse.ant-collapse-icon-position-end
    > .ant-collapse-item
    > .ant-collapse-header
    .ant-collapse-expand-icon {
    order: 1;
    padding-inline-end: 0;
    padding-inline-start: 0 !important;
  }
  &:where(.css-dev-only-do-not-override-p7e5j5).ant-collapse
    > .ant-collapse-item
    > .ant-collapse-header
    .ant-collapse-expand-icon {
    padding-inline-end: 0;
    width: 25px;
    justify-content: center;
  }
`;
