/** @format */

import { AuthProvider } from './Auth';
import SafetyDashboardProvider from './SafetyDashoard';
import { DispatcherTeamsProvider } from './Hr/DispatcherTeams';
import { DriversProvider } from './Hr/Drivers';
import { QCTeamsProvider } from './Hr/QCTeams';
import { UsersProvider } from './Hr/Users';
import { RolenameContextProvider } from './Hr/RoleNameContext';
import { CompaniesPovider } from './DataLibrary/Company';
import { CustomersProvider } from './Accounting/Customers';
import { DocTypeProvider } from './DataLibrary/DocType';
import { ZoneContextProvider } from './DataLibrary/Zone';
import { OfficeProvider } from './Hr/Office';
import { MedicalCertificateContextProvider } from './Hr/MedicalCertificates';
import { EmployeeProvider } from './Hr/Employee';
import { DepartmentProvider } from './Hr/Department';
import { CDLContextProvider } from './Hr/Cdl';
import LocationProvider from './DataLibrary/Location';
import { TruckPovider } from './Fleet/Trucks';
import { ReservationsPovider } from './Fleet/Reservation';
import { OriginalContractPovider } from './Fleet/OrginalContracts';
import { TruckWeeklyInspectionsProvider } from './Fleet/Inspections/Weekly';
import { TruckAnnualInspectionsProvider } from './Fleet/Inspections/Annual';
import { PmProvider } from './Fleet/Pm';
import { OnBoardingProvider } from './Fleet/OnBoarding';
import { RegCardProvider } from './Fleet/RegCard';
import { PermitProvider } from './Fleet/Permit';
import { TruckWarningProvider } from './Fleet/TruckWarning';
import { TruckDashboardProvider } from './Fleet/TruckDashboard';
import { TruckMaintenancePovider } from './Fleet/TruckMaintenance';
import { TruckServicePovider } from './Fleet/TruckService';
import { CategoryProvider } from './DataLibrary/Category';
import { TrailersContextProvider } from './Trailer/Trailers';
import { TrailerWarningPovider } from './Trailer/Warning';
import { TireAndBrakeProvider } from './Trailer/TireAndBrake';
import { InspectionProvider } from './Fleet/Inspection';
import { TrailerMovementProvider } from './Trailer/Movement';
import { TrailersMovesPovider } from './Trailer/Moves';
import { TrailerInspectionsPovider } from './Trailer/TrailerInspection';
import { TrailerRegCardsPovider } from './Trailer/RegCards';
import { TrailerMaintenancePovider } from './Trailer/TrailerMaintenance';
import { TrailerServicePovider } from './Trailer/TrailerService';
import { HiringDriverProvider } from './Hr/HiringDriver';
import { AddConnectionsContextProvider } from './SettingsList/AddConnections';
import { ConnectionsProvider } from './SettingsList/Connections';
import ProfileProvider from './Profile';
import { CronTaskProvider } from './SettingsList/CronTask';
import { TruckGPSProvider } from './SettingsList/TruckGPS';
import { ServersProvider } from './SettingsList/Servers';
import { DispatcherBoardProvider } from './Dispatcher/DispatcherBoard';
import { DispatcherTeamSheetProvider } from './Dispatcher/DispatcherTeamSheet';
import { LoadsProvider } from './Load/Loads';
import { PerformanceProvider } from './Performance';
import { TrailerReqsProvider } from './Trailer/TrailerRequests';
import { FuelManagementPovider } from './Fuel/FuelManagement';
import { DispatcherKpiBoardProvider } from './Dispatcher/DispatcherKpiBoard';
import { RankingProvider } from './Dispatcher/Ranking';
import { TeamsProvider } from './Hr/Teams';
import { DriverOnBoardingProvider } from './Hr/DriverOnBoarding';
import { StableNavigateContextProvider } from './StableNavigate';
import { TruckPAInspectionsProvider } from './Fleet/Inspections/Pa';
import { DedicatedLineProvider } from './DedicatedLine';
import { DriversCalendarContextProvider } from './Hr/DriversCalendar';
import { FuelStationsProvider } from './Fuel/FuelStations';
import { GrossBoardDataProvider } from './GrossBoard';
import OptionsProvider from './useOptions';
import { FuelExpenseProvider } from './Fuel/FuelExpense';
import { ConstantsPovider } from './DataLibrary/Constants';
import { FactoringPovider } from './DataLibrary/Factoring';
import { QcReportPovider } from './QcBoard/QcReport';
import { FuelDashboardProvider } from './Fuel/FuelDashboard';
import { PolicyProvider } from './Insurance/Policy';
import { PrePlanningProvider } from './PrePlanning';
import { ChangeProvider } from './Insurance/Change';
import { EndorsementProvider } from './Insurance/Endorsement';
import { AccidentProvider } from './Insurance/Accident';
import { ExpenseProvider } from './Insurance/Expense';
import { InsuranceStatisticsProvider } from './Insurance/Dashboard';
import { KanbanBoardContextProvider } from './Hr/KanbanBoard';
import { BlackListContextProvider } from './Hr/BlackList';
import { AccidentExpensesProvider } from './Insurance/AccidentExpenses';
import { ViolationProvider } from './Insurance/Violation';
import { DOTReportProvider } from './Insurance/DOTReport';
import { FMCSAProvider } from './Insurance/FMCSA';
import { CitationProvider } from './Insurance/Citation';
import { ExistContextProvider } from './Fuel/Exist';
import { LoadBillingProvider } from './Accounting/LoadBilling';
import { FuelCardContextProvider } from './Fuel/FuelCard';
import { AdditionDeductionProvider } from './Accounting/AdditiontionDeduction';
import { DefaultEmailsProvider } from './SettingsList/DefaultEmails';
import { TripCodeProvider } from './Accounting/TripCode';
import { ChartOfAccountProvider } from './Accounting/ChartOfAccount';

const contextProviders = [
  DedicatedLineProvider,
  AuthProvider,
  SafetyDashboardProvider,
  DispatcherTeamsProvider,
  TeamsProvider,
  DriversProvider,
  QCTeamsProvider,
  UsersProvider,
  RolenameContextProvider,
  CompaniesPovider,
  CustomersProvider,
  DocTypeProvider,
  ZoneContextProvider,
  OfficeProvider,
  MedicalCertificateContextProvider,
  EmployeeProvider,
  DepartmentProvider,
  CDLContextProvider,
  LocationProvider,
  TruckPovider,
  ReservationsPovider,
  OriginalContractPovider,
  TruckWeeklyInspectionsProvider,
  TruckAnnualInspectionsProvider,
  PmProvider,
  OnBoardingProvider,
  RegCardProvider,
  PermitProvider,
  TruckWarningProvider,
  TruckDashboardProvider,
  TruckMaintenancePovider,
  TruckServicePovider,
  CategoryProvider,
  TrailersContextProvider,
  TrailerWarningPovider,
  TireAndBrakeProvider,
  InspectionProvider,
  TrailerMovementProvider,
  TrailersMovesPovider,
  TrailerInspectionsPovider,
  TrailerRegCardsPovider,
  TrailerMaintenancePovider,
  TrailerServicePovider,
  HiringDriverProvider,
  CompaniesPovider,
  ConnectionsProvider,
  AddConnectionsContextProvider,
  ProfileProvider,
  CronTaskProvider,
  TruckGPSProvider,
  ServersProvider,
  DispatcherBoardProvider,
  DispatcherTeamSheetProvider,
  LoadsProvider,
  PerformanceProvider,
  TrailerReqsProvider,
  FuelManagementPovider,
  DispatcherKpiBoardProvider,
  RankingProvider,
  DriverOnBoardingProvider,
  StableNavigateContextProvider,
  TruckPAInspectionsProvider,
  DriversCalendarContextProvider,
  FuelStationsProvider,
  GrossBoardDataProvider,
  OptionsProvider,
  FuelExpenseProvider,
  ConstantsPovider,
  ConstantsPovider,
  FactoringPovider,
  QcReportPovider,
  FuelDashboardProvider,
  PolicyProvider,
  PrePlanningProvider,
  ChangeProvider,
  EndorsementProvider,
  AccidentProvider,
  ExpenseProvider,
  InsuranceStatisticsProvider,
  KanbanBoardContextProvider,
  BlackListContextProvider,
  AccidentExpensesProvider,
  ViolationProvider,
  DOTReportProvider,
  FMCSAProvider,
  CitationProvider,
  ExistContextProvider,
  LoadBillingProvider,
  FuelCardContextProvider,
  AdditionDeductionProvider,
  DefaultEmailsProvider,
  TripCodeProvider,
  ChartOfAccountProvider
];

const MainContextProvider = ({ children }) => {
  return contextProviders.reduceRight((acc, ContextProvider) => {
    return <ContextProvider>{acc}</ContextProvider>;
  }, children);
};

export default MainContextProvider;

export {
  AuthProvider,
  SafetyDashboardProvider,
  ZoneContextProvider,
  RolenameContextProvider,
  DocTypeProvider,
  UsersProvider,
  CompaniesPovider,
  CustomersProvider,
  DispatcherTeamsProvider,
  TeamsProvider,
  QCTeamsProvider,
  DriversProvider,
  MedicalCertificateContextProvider,
  OfficeProvider,
  EmployeeProvider,
  CDLContextProvider,
  DepartmentProvider,
  TruckPovider,
  ReservationsPovider,
  PmProvider,
  OnBoardingProvider,
  RegCardProvider,
  PermitProvider,
  TruckMaintenancePovider,
  TruckServicePovider,
  CategoryProvider,
  TrailersContextProvider,
  TrailerWarningPovider,
  TireAndBrakeProvider,
  InspectionProvider,
  TrailerMovementProvider,
  TrailersMovesPovider,
  TrailerInspectionsPovider,
  TrailerRegCardsPovider,
  TrailerMaintenancePovider,
  TrailerServicePovider,
  HiringDriverProvider,
  ConnectionsProvider,
  AddConnectionsContextProvider,
  ProfileProvider,
  CronTaskProvider,
  TruckGPSProvider,
  ServersProvider,
  LoadsProvider,
  TrailerReqsProvider,
  FuelManagementPovider,
  DispatcherKpiBoardProvider,
  RankingProvider,
  DriverOnBoardingProvider,
  TruckPAInspectionsProvider,
  DedicatedLineProvider,
  DriversCalendarContextProvider,
  FuelStationsProvider,
  GrossBoardDataProvider,
  OptionsProvider,
  FuelExpenseProvider,
  ConstantsPovider,
  FactoringPovider,
  QcReportPovider,
  FuelDashboardProvider,
  PolicyProvider,
  PrePlanningProvider,
  ChangeProvider,
  EndorsementProvider,
  AccidentProvider,
  ExpenseProvider,
  InsuranceStatisticsProvider,
  KanbanBoardContextProvider,
  BlackListContextProvider,
  AccidentExpensesProvider,
  ViolationProvider,
  DOTReportProvider,
  FMCSAProvider,
  CitationProvider,
  ExistContextProvider,
  LoadBillingProvider,
  FuelCardContextProvider,
  AdditionDeductionProvider,
  DefaultEmailsProvider,
  TripCodeProvider,
  ChartOfAccountProvider
};
