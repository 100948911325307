/** @format */

import { Label, AutoCompleteWrapper, StyledAutoComplete } from './style';
import RedStars from '../RedStars';

const Autocomplete = ({
  isrequired,
  label,
  options,
  value,
  placeholder,
  border,
  labelColor,
  isPending,
  isKeyOption,
  onSearch,
  ...props
}) => {
  return (
    <AutoCompleteWrapper border={border}>
      {label && (
        <Label labelColor={labelColor}>
          {label}
          {isrequired ? <RedStars /> : null}
        </Label>
      )}
      <StyledAutoComplete
        popupMatchSelectWidth={false}
        showSearch
        placeholder={placeholder || 'Search to Select'}
        onSearch={onSearch}
        options={options}
        value={value || null}
        notFoundContent={isPending ? 'Loading...' : 'No data found!'}
        filterOption={(input, option) => {
          if (option?.value)
            return (
              option?.value?.toUpperCase()?.indexOf(input?.toUpperCase()) >= 0
            );
        }}
        {...props}
      >
        {options?.map((opt, index) => {
          let value = opt?.value;
          let count = 1;

          for (let i = 0; i < index; i++) {
            if (value === options[i]?.value) {
              count++;
            }
          }

          if (count > 1) {
            value += ' ' + count;
          }

          return (
            <StyledAutoComplete.Option
              key={index.toString()}
              value={opt?.id}
              label={value}
            >
              {isKeyOption ? opt.key : value}
            </StyledAutoComplete.Option>
          );
        })}
      </StyledAutoComplete>
    </AutoCompleteWrapper>
  );
};

export default Autocomplete;
