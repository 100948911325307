/** @format */

import React from 'react';

const Connections = React.lazy(() => import('./Connections'));
const CronTasks = React.lazy(() => import('./CronTasks'));
const TruckGps = React.lazy(() => import('./TruckGPS'));
const Servers = React.lazy(() => import('./Servers'));
const DefaultEmails = React.lazy(() => import('./DefaultEmails'));
export { Connections, CronTasks, TruckGps, Servers, DefaultEmails };
